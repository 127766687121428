import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  useMemo,
} from "react";
import secureLocalStorage from "react-secure-storage";
import Modal from "../../../UiComponents/Modal";

import FormHeader from "../../../Basic/components/FormHeader";
import { toast } from "react-toastify";
import {
  DropdownInput,
  TextArea,
  DisabledInput,
  TextInput,
  CheckBox,
  LongDisabledInput,
} from "../../../Inputs";

import { useGetUomQuery } from "../../../redux/services/UomMasterService";
import { dropDownListObject } from "../../../Utils/contructObject";
import { findFromList, getDateFromDateTime } from "../../../Utils/helper";
import {
  useGetPartyByIdQuery,
  useGetPartyQuery,
} from "../../../redux/services/PartyMasterService";
import { Loader } from "../../../Basic/components";
import { useGetStateQuery } from "../../../redux/services/StateMasterService";
import {
  useGetProjectQuery,
  useGetProjectByIdQuery,
  useAddProjectMutation,
  useUpdateProjectMutation,
  useDeleteProjectMutation,
} from "../../../redux/services/ProjectService";
import Report from "./Report";
import LineItems from "./LineItems";
import {
  useGetQuotesByIdQuery,
  useGetQuotesQuery,
  useUpdateQuotesMutation,
} from "../../../redux/services/QuotesService";
import PartySearchOnly from "./PartySearchOnly";
import { forEach } from "lodash";
import moment from "moment";
import ProjectPayments from "./ProjectPayments";
import ProjectExpenses from "./ProjectExpenses";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { push } from "../../../redux/features/opentabs";
import { useGetLeadQuery } from "../../../redux/services/LeadFormService";
import QuoteDropdown from "../../ReusableComponents/QuoteDropdown";
import { faProjectDiagram } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useInvalidateTags from "../../../CustomHooks/useInvalidateTags";

const MODEL = "Project";

export default function Form({ navigateProjectId }) {
  const dispatch = useDispatch();
  const [id, setId] = useState();
  const [quoteId, setQuoteId] = useState("");

  const openTabs = useSelector((state) => state.openTabs);
  const [invalidateTagsDispatch] = useInvalidateTags();

  const branchId = secureLocalStorage.getItem(
    sessionStorage.getItem("sessionId") + "currentBranchId"
  );

  const companyId = secureLocalStorage.getItem(
    sessionStorage.getItem("sessionId") + "userCompanyId"
  );

  const params = {
    branchId,
    companyId,
  };

  const {
    data: allData,
    isLoading,
    isFetching,
  } = useGetProjectQuery({ params: { branchId } });

  const projectUpdate = openTabs.tabs.find(
    (i) => i.name == "PROJECT"
  )?.projectId;
  const quoteUpdate = openTabs.tabs.find((i) => i.name == "PROJECT")?.quoteId;
  const projectForm = openTabs.tabs.find(
    (i) => i.name == "PROJECT"
  )?.projectForm;

  useEffect(() => {
    if (!projectUpdate && !quoteUpdate) return;

    if (projectUpdate == "new") {
      setQuoteId(quoteUpdate);
      setId();
      setForm(projectForm);
    } else {
      setId(projectUpdate);
      setForm(projectForm);
    }
    // dispatch(push({ name: "PROJECT", quoteId: null }))
    dispatch(push({ name: "PROJECT", projectForm: null }));
    // dispatch(push({ name: "PROJECT", projectId: null }))
  }, [projectUpdate, quoteUpdate, setQuoteId, setId, projectForm]);

  const today = new Date();
  const [form, setForm] = useState(
    openTabs.tabs.find((i) => i.name === "PROJECT")?.projectForm || false
  );
  const [readOnly, setReadOnly] = useState(false);

  const [projectName, setProjectName] = useState("");
  const [description, setDescription] = useState("");
  const [quotesItems, setQuotesItems] = useState([]);
  const [date, setDate] = useState(
    getDateFromDateTime(moment.utc(today).format("DD-MM-YYYY"))
  );
  const [docId, setDocId] = useState("");
  const [formReport, setFormReport] = useState(false);
  const [lineItems, setLineItems] = useState([]);
  const [projectArray, setProjectArray] = useState([]);
  const [projectAddress, setProjectAddress] = useState([]);
  const [clientId, setClientId] = useState("");
  const [address, setAddress] = useState("");
  const [location, setLocation] = useState("");
  const [leadId, setLeadId] = useState();
  const [quotesUpdate, setQuotesUpdate] = useState(false);
  const [shippingAddressId, setShippingAddressId] = useState("");
  const [addressData, setAddressData] = useState([]);
  const [isOpenExpenses, setIsOpenExpenses] = useState(false);
  const [isOpenPayments, setIsOpenPayments] = useState(false);
  const childRecord = useRef(0);
  const [searchValue, setSearchValue] = useState("");
  const [lineEditableIndex, setLineEditableIndex] = useState(null);
  const [isProjectCompleted, setIsProjectCompleted] = useState(false);

  const {
    data: singleQuoteData,
    isFetching: isSingleQuoteFetching,
    isLoading: isSingleQuoteLoading,
  } = useGetQuotesByIdQuery(quoteId, { skip: !quoteId });

  useEffect(() => {
    if (id) return;
    if (!quoteId) return;
    setLeadId(singleQuoteData?.data?.LeadForm?.id || "");
    setReadOnly(false);
    setLocation("");
    setDescription("");
    setDate(moment.utc(today).format("DD-MM-YYYY"));
    setProjectName(singleQuoteData?.data?.projectName || "");
    setClientId(singleQuoteData?.data?.clientId || "");
  }, [
    quoteId,
    projectUpdate,
    quoteUpdate,
    singleQuoteData,
    setClientId,
    setProjectName,
    isSingleQuoteFetching,
    isSingleQuoteLoading,
  ]);

  const {
    data: singleData,
    isFetching: isSingleFetching,
    isLoading: isSingleLoading,
  } = useGetProjectByIdQuery(id, { skip: !id });

  const [addData] = useAddProjectMutation();
  const [updateData] = useUpdateProjectMutation();
  const [removeData] = useDeleteProjectMutation();

  const { data: partyList } = useGetPartyQuery({ params });
  const { data: quoteList } = useGetQuotesQuery({ params });
  const { data: leadList } = useGetLeadQuery({ params });
  const { data: stateList } = useGetStateQuery({ params });

  const { data: uomList } = useGetUomQuery({ params });

  // function getNextDocId() {
  //   if (id || isLoading || isFetching) return;
  //   if (allData?.nextDocId) {
  //     setDocId(allData.nextDocId);
  //   }
  // }

  const getNextDocId = useCallback(() => {
    if (id || isLoading || isFetching) return;
    if (allData?.nextDocId) {
      setDocId(allData.nextDocId);
    }
  }, [allData, isLoading, isFetching, id]);

  useEffect(getNextDocId, [getNextDocId]);

  const syncFormWithDb = useCallback(
    (data) => {
      if (!id) return;
      if (quoteUpdate) return;
      if (id) setReadOnly(true);
      if (data?.docId) {
        setDocId(data?.docId);
      }

      setDate(
        data?.createdAt
          ? moment.utc(data.createdAt).format("DD-MM-YYYY")
          : moment.utc(today).format("DD-MM-YYYY")
      );

      setProjectName(
        data?.projectName
          ? data?.projectName
          : data?.Quotes
            ? data?.Quotes[0]?.LeadForm?.projectName
            : ""
      );
      setLeadId(
        data?.leadId
          ? data?.leadId
          : data?.Quotes
            ? data?.Quotes[0]?.leadId
            : ""
      );
      setIsProjectCompleted(
        id
          ? data?.isProjectCompleted
            ? data?.isProjectCompleted
            : false
          : false
      );
      setDescription(data?.description ? data?.description : "");
      setClientId(data?.clientId || "");
      setQuoteId(
        data?.quoteId ? data?.quoteId : data?.Quotes ? data?.Quotes[0]?.id : ""
      );
      setAddress(data?.address || "");
      setLocation(data?.location || "");
      setLineItems(data?.LineItems ? data?.LineItems : []);
      setLineEditableIndex(null);
      childRecord.current = data?.childRecord ? data?.childRecord : 0;
    },
    [id]
  );

  useEffect(() => {
    syncFormWithDb(singleData?.data);
  }, [isSingleFetching, isSingleLoading, id, syncFormWithDb, singleData]);

  const { data: clientData } = useGetPartyByIdQuery(clientId, {
    skip: !clientId,
  });
  const { data: quotesData } = useGetQuotesQuery({ params: { branchId } });

  useEffect(() => {
    if (id || !clientData) return;
    let address = clientData?.data?.address || "";
    setAddress(address);
  }, [clientData, id]);

  const data = {
    id,
    projectName,
    branchId,
    clientId,
    location,
    address,
    description,
    isProjectCompleted,
    lineItems,
    leadId,
    quoteId,
  };

  const validateData = (data) => {
    if (!data.clientId || !data.projectName || !data?.quoteId) return false;
    if (data?.lineItems?.length === 0) return false;
    return true;
  };

  const handleSubmitCustom = async (callback, data, text) => {
    try {
      let returnData = await callback(data).unwrap();
      if (returnData.statusCode === 0) {
        setId(returnData?.data?.id);
        // syncFormWithDb(returnData?.data)
        toast.success(text + "Successfully");
        // setQuoteId("");
        // setLineItems([])
      } else {
        toast.error(returnData?.message);
      }
      invalidateTagsDispatch();

      dispatch({
        type: `partyMaster/invalidateTags`,
        payload: ["Party"],
      });
    } catch (error) {
      console.log("handle");
    }
  };

  const handleQuotesUpdate = async (callback, lineItemsData, text) => {
    try {
      await callback(lineItemsData).unwrap();
      toast.success(text + "Successfully");
    } catch (error) {
      console.log("handle");
    }
  };

  const saveData = () => {
    if (!validateData(data)) {
      toast.info("Please fill all required fields...!", {
        position: "top-center",
      });
      return;
    }
    if (!window.confirm("Are you sure save the details ...?")) {
      return;
    }

    if (id) {
      handleSubmitCustom(updateData, data, "Updated");
    } else {
      handleSubmitCustom(addData, data, "Added");
    }
  };

  const deleteData = async () => {
    if (id) {
      if (!window.confirm("Are you sure to delete...?")) {
        return;
      }
      try {
        let returnData = await removeData(id).unwrap();
        if (returnData.statusCode === 0) {
          setId("");
          syncFormWithDb(undefined);
          toast.success("Deleted Successfully");
          invalidateTagsDispatch();
        } else {
          toast.error(returnData?.message);
        }
      } catch (error) {
        toast.error("something went wrong");
      }
    }
  };

  const handleKeyDown = (event) => {
    let charCode = String.fromCharCode(event.which).toLowerCase();
    if ((event.ctrlKey || event.metaKey) && charCode === "s") {
      event.preventDefault();
      saveData();
    }
  };

  const onNew = () => {
    if (allData?.nextDocId) {
      setDocId(allData.nextDocId);
    }
    setId("");
    setForm(true);
    setReadOnly(false);
    setSearchValue("");
    setLineItems([]);
    setClientId("");
    setProjectName("");
    setLeadId("")

    // syncFormWithDb(undefined);

    setAddress("");
  };

  function onDataClick(id) {
    setId(id);
    setForm(true);
  }

  useEffect(() => {
    let Data = partyList?.data?.find(
      (val) => parseInt(val.id) === parseInt(clientId)
    )?.ShippingAddress;
    setAddressData(Data);
  }, [clientId, partyList, id]);

  function refreshProjectItems() {
    if (!quoteId || !clientId || !id) return;
    let currentLineItems = lineItems;
    let currentVersion = singleQuoteData?.data?.quoteVersion;
    let currentVersionLineItems = singleQuoteData?.data?.QuotesItems?.filter(
      (item) => parseInt(item.quoteVersion) === parseInt(currentVersion)
    );
    let samelineItems = currentLineItems?.filter((val) =>
      currentVersionLineItems?.find((item) => item.productId == val.productId)
    );


    setLineItems(prev => {
      let newLineItems = [];
      currentVersionLineItems?.forEach((data, index) => {
        newLineItems.push({
          quoteId: singleQuoteData?.data?.id,
          quoteLineItemId: data?.id,
          productId: data?.productId,
          description: data?.description,
          hsnCode: data?.hsnCode,
          uomId: data?.uomId,
          qty: data?.qty,
          planEndDate: "",
          planStartDate: "",
          leadDays: "",
          isQuote: true,
          isCompleted: false,
          subLineItems: [

          ]

        })


      })
      return newLineItems
    }
    )


    for (let i = 0; i < currentVersionLineItems?.length; i++) {

      setLineItems(prev => {
        let newObj = structuredClone(prev);
        let addSubLineItems = (samelineItems?.find(item => (parseInt(item.productId) == parseInt(newObj[i]["productId"])))?.subLineItems || [])
        let startDate = (samelineItems?.find(item => (parseInt(item.productId) == parseInt(newObj[i]["productId"])))?.planStartDate || "")
        let ToDate = (samelineItems?.find(item => (parseInt(item.productId) == parseInt(newObj[i]["productId"])))?.planEndDate || "")
        let leadDay = (samelineItems?.find(item => (parseInt(item.productId) == parseInt(newObj[i]["productId"])))?.leadDays || "")
        newObj[i]["subLineItems"] = addSubLineItems;
        newObj[i]["planStartDate"] = startDate;
        newObj[i]["planEndDate"] = ToDate;
        newObj[i]["leadDays"] = leadDay;

        return newObj
      }

      )
    }




  }


  // useEffect(() => {
  //   if (!clientId) return
  //   setProjectArray([])
  //   let projectList = quoteList?.data?.filter(val => parseInt(val?.clientId) === parseInt(clientId))
  //   if (projectList?.length > 0) {
  //     const uniqueArray = projectList.filter((item, index) => {
  //       return projectList.findIndex(i => i.projectName === item.projectName) === index;
  //     });
  //     setProjectArray(uniqueArray)
  //   }
  //   else {
  //     return setProjectName(singleData?.data?.projectName ? singleData?.data?.projectName : "")
  //   }

  // }, [clientId, setProjectArray, quoteList])

  function isCheckProjectCompleted() {
    if (lineItems?.length > 0) {
      return lineItems?.every((val) => val.isCompleted);
    }


    return false;


  }

  if (!form)
    return (
      <Report
        setQuotesUpdate={setQuotesUpdate}
        onClick={onDataClick}
        onNew={onNew}
        onNewButton={true}
      />
    );
  if (!partyList || !uomList || !stateList) return <Loader />;
  return (
    <div
      onKeyDown={handleKeyDown}
      className="md:items-start md:justify-items-center grid h-full bg-theme"
    >
      <Modal
        isOpen={formReport}
        onClose={() => setFormReport(false)}
        widthClass={"px-2 h-[100%] w-[90%]"}
      >
        <Report
          setQuotesUpdate={setQuotesUpdate}
          onClick={(id) => {
            setId(id);
            setFormReport(false);
            setQuotesUpdate(true);
          }}
          onNewButton={false}
        />
      </Modal>
      {/* <Modal

        isOpen={isOpenPayments}
        onClose={() => setIsOpenPayments(false)}
        widthClass={"px-2 h-[90%] w-[90%]"}

      >
        <ProjectPayments partyId={clientId} projectId={id} address={address} onClick={() => { setIsOpenPayments(false); }} setIsOpenPayments={setIsOpenPayments} isOpenPayments={isOpenPayments} />
      </Modal>


      <Modal

        isOpen={isOpenExpenses}
        onClose={() => setIsOpenExpenses(false)}
        widthClass={"px-2 h-[90%] w-[90%]"}

      >
        <ProjectExpenses projectDocId={docId} projectName={projectName} partyId={clientId} projectId={id} address={address} onClick={() => { setIsOpenExpenses(false); }} setIsOpenExpenses={setIsOpenExpenses} />
      </Modal> */}
      <div className="flex flex-col frame w-full h-full">
        <FormHeader
          onNew={onNew}
          onClose={() => {
            setForm(false);
            setSearchValue("");
          }}
          model={MODEL}
          openReport={() => setFormReport(true)}
          saveData={saveData}
          setReadOnly={setReadOnly}
          deleteData={deleteData}
        />
        <div className="flex-1 grid grid-cols-1 md:grid-cols-4 gap-x-2 overflow-clip">
          <div className="col-span-4 grid md:grid-cols-1 border overflow-auto">
            <div className="mr-1 md:ml-2">
              <fieldset className="frame my-1">
                <legend className="sub-heading">Project Info</legend>

                <div className="grid grid-cols-6 gap-2 bg-gray-200 ">
                  <div className="col-span-1">
                    <DisabledInput
                      name="DocId"
                      value={docId}
                      required={true}
                      readOnly={false}
                    />
                  </div>
                  <div className="col-span-1">
                    <DisabledInput
                      name="Doc.Date"
                      value={date}
                      required={true}
                      readOnly={false}
                    />
                  </div>

                  <div className="col-span-1">
                    <QuoteDropdown
                      id={id}
                      projectDatas={allData?.data}
                      readOnly={readOnly}
                      quoteId={clientId}
                      multiSelect={false}
                      withoutLabel={false}
                      name="Quote"
                      selected={quoteId}
                      setSelected={setQuoteId}
                      clientId={clientId}
                    />
                  </div>

                  <TextInput
                    name="Project Name"
                    value={projectName}
                    setValue={setProjectName}
                    className="w-full"
                    readOnly={readOnly}
                  />
                  <div className="col-span-1 ">
                    <label className={`md:text-start flex text-sm pb-1 `}>
                      Location
                    </label>
                    <input
                      type={"text"}
                      className={`${"input-field focus:outline-none md:col-span-2 border-gray-500 w-full p-1 border rounded"}`}
                      value={location}
                      onChange={(e) => {
                        setLocation(e.target.value);
                      }}
                      readOnly={readOnly}
                    />
                  </div>
                  <div className="col-span-1">
                    <DisabledInput
                      name="Lead No."
                      value={findFromList(leadId, leadList?.data, "docId")}
                      required={true}
                      readOnly={false}
                    />
                  </div>

                  <div className="col-span-1">
                    <PartySearchOnly
                      setPartyId={setClientId}
                      partyId={clientId}
                      name={"Client Name"}
                      id={id}
                    />
                  </div>
                  <div className="col-span-2">
                    <label className="md:text-start text-sm flex">
                      Address
                    </label>
                    <textarea
                      className=" focus:outline-none md:col-span-2 border border-gray-500 text-sm rounded h-12 md:w-full"
                      value={address}
                      onChange={(e) => {
                        setAddress(e.target.value);
                      }}
                      readOnly={readOnly}
                    ></textarea>
                  </div>
                  <div className="col-span-2">
                    <label className="md:text-start flex text-sm">
                      Description
                    </label>
                    <textarea
                      className="focus:outline-none md:col-span-2 h-12 border border-gray-500 text-sm rounded md:w-full"
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                      readOnly={readOnly}
                    ></textarea>
                  </div>
                  {isCheckProjectCompleted() && (
                    <div className="md:ml-4 pt-2">
                      <CheckBox
                        name="IsProjectCompleted"
                        value={isProjectCompleted}
                        setValue={setIsProjectCompleted}
                        readOnly={readOnly}
                      />
                    </div>
                  )}

                  {/* <div className=''>
                    <div className={`flex gap-x-2 md:gap-x-7 items-center md:my-0.5 md:px-1 data `}>

                      <label className={`md:text-start flex  `}>Project Name </label>
                      <select
                        disabled={id ? true : false}

                        className='text-left w-20 md:w-44 rounded py-1 table-data-input border border-gray-400'
                        value={projectName}
                        onChange={(e) => setProjectName(e.target.value)}
                      >
                        <option className='text-gray-600'>
                          select
                        </option>
                        {projectArray?.map((data) =>
                          <option value={data.projectName} key={data.projectName}>
                            {data.projectName}
                          </option>
                        )}
                      </select>
                    </div>
                  </div> */}
                </div>

                <div className="p-1 bg-gray-200"   >
                  <button className='bg-green-600 text-white rounded-md py-2 px-4 text-md w-32 flex items-center justify-center' onClick={refreshProjectItems}>
                    <FontAwesomeIcon icon={faProjectDiagram} className='mr-2' /> Refresh
                  </button>
                </div>
              </fieldset>
              <LineItems
                quoteUpdate={quoteUpdate}
                projectUpdate={projectUpdate}
                lineEditableIndex={lineEditableIndex}
                setLineEditableIndex={setLineEditableIndex}
                readOnly={readOnly}
                lineItems={lineItems}
                setLineItems={setLineItems}
                quoteId={quoteId}
                id={id}
                projectName={projectName}
                clientId={clientId}
                saveData={saveData}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
