import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

const Modal1 = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div className="absolute inset-0 bg-black opacity-50"></div>
      <div
        className="bg-amber-50 rounded-lg shadow-lg p-6 max-w-4xl w-full max-h-4xl overflow-y-auto relative"
        style={{ maxWidth: '75%', maxHeight: '100%' }}
      >
        <button onClick={onClose} className="absolute top-2 right-2 bg-red-700 p-1 text-white rounded-full hover:text-gray-900">
          <FontAwesomeIcon icon={faTimes} />
        </button>
        {children}
      </div>
    </div>
  );
};

export default Modal1;
