import React from 'react';
import ReactDOM from 'react-dom';
import { push } from "../../../redux/features/opentabs";
import { useDispatch } from "react-redux";

const InvoiceTableModal = ({ isOpen, onRequestClose, invoiceData }) => {
    const dispatch = useDispatch();

    const invoiceOpen = (InvoiceId) => () => {
        let quoteId;
        let projectId;

        if (InvoiceId) {
            projectId = InvoiceId;
        }

        dispatch(
            push({
                name: "INVOICE",
                projectId: projectId,
                quoteId: quoteId,
                projectForm: true,
            })
        );
    };

    if (!isOpen) return null;

    return ReactDOM.createPortal(
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center z-50">
            <div className="bg-white p-6 rounded-lg shadow-lg max-w-4xl w-full">
                <h2 className="text-xl font-bold mb-4">Invoice Details</h2>
                <button
                    onClick={onRequestClose}
                    className="bg-red-500 text-white px-4 py-2 rounded mb-4 hover:bg-red-600 focus:outline-none"
                >
                    Close
                </button>
                <table className="w-full table-auto border-collapse border border-gray-300">
                    <thead>
                        <tr className="bg-gray-100">
                            <th className="border border-gray-300 p-2">Created At</th>
                            <th className="border border-gray-300 p-2">Document ID</th>
                            <th className="border border-gray-300 p-2">Party Name</th>
                            <th className="border border-gray-300 p-2">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {invoiceData?.data.length === 0 ? (
                            <tr>
                                <td colSpan="4" className="border border-gray-300 p-2 text-center text-gray-500">
                                    No Data Found
                                </td>
                            </tr>
                        ) : (
                            invoiceData.data.map(invoice => (
                                <tr key={invoice.id}>
                                    <td className="border border-gray-300 p-2">{new Date(invoice.createdAt).toLocaleDateString()}</td>
                                    <td className="border border-gray-300 p-2">{invoice.docId}</td>
                                    <td className="border border-gray-300 p-2">{invoice.Party.name}</td>
                                    <td className="border border-gray-300 p-2 text-center">
                                        <button
                                            className="bg-blue-500 text-white px-4 py-1 rounded hover:bg-blue-600"
                                            onClick={invoiceOpen(invoice.id)}
                                        >
                                            View
                                        </button>
                                    </td>
                                </tr>
                            ))
                        )}
                    </tbody>
                </table>
            </div>
        </div>,
        document.body
    );
};

export default InvoiceTableModal;
