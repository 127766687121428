import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { INVOICE_API } from "../../Api";
import ProjectFormApi from "./ProjectService";
import quotesApi from "./QuotesService";
import leadFormApi from "./LeadFormService";

const BASE_URL = process.env.REACT_APP_SERVER_URL;

async function invalidateTagsHelperInvoice(dispatch) {
    dispatch(ProjectFormApi.util.invalidateTags(["Project"]));
    dispatch(leadFormApi.util.invalidateTags(["Lead"]));
    dispatch(quotesApi.util.invalidateTags(["Quotes"]));
  }
  

const InvoiceFormApi = createApi({
    reducerPath: "Invoice",
    baseQuery: fetchBaseQuery({
        baseUrl: BASE_URL,
    }),
    tagTypes: ["Invoice"],
    endpoints: (builder) => ({
        getInvoice: builder.query({
            query: ({ params, searchParams }) => {
                if (searchParams) {
                    return {
                        url: INVOICE_API + "/search/" + searchParams,
                        method: "GET",
                        headers: {
                            "Content-type": "application/json; charset=UTF-8",
                        },
                        params
                    };
                }
                return {
                    url: INVOICE_API,
                    method: "GET",
                    headers: {
                        "Content-type": "application/json; charset=UTF-8",
                    },
                    params
                };
            },
            providesTags: ["Invoice"],
        }),
        getInvoiceById: builder.query({
            query: (id) => {
                return {
                    url: `${INVOICE_API}/${id}`,
                    method: "GET",
                    headers: {
                        "Content-type": "application/json; charset=UTF-8",
                    },
                };
            },
            providesTags: ["Invoice"],
        }),
        addInvoice: builder.mutation({
            query: (payload) => ({
                url: INVOICE_API,
                method: "POST",
                body: payload,
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                },
            }),
            invalidatesTags: ["Invoice"],
            async onQueryStarted(arg, {dispatch}){
              invalidateTagsHelperInvoice(dispatch)
            }
        }),
        updateInvoice: builder.mutation({
            query: (payload) => {
                const { id, ...body } = payload;
                return {
                    url: `${INVOICE_API}/${id}`,
                    method: "PUT",
                    body,
                };
            },
            invalidatesTags: ["Invoice"],
            async onQueryStarted(arg, {dispatch}){
              invalidateTagsHelperInvoice(dispatch)
            }
        }),
        updateManyInvoice: builder.mutation({
            query: (payload) => {
                const { companyId, Invoicees } = payload;
                return {
                    url: `${INVOICE_API}/updateMany/${companyId}`,
                    method: "PUT",
                    body: Invoicees,
                };
            },
            invalidatesTags: ["Invoice"],
            async onQueryStarted(arg, {dispatch}){
              invalidateTagsHelperInvoice(dispatch)
            }
        }),
        deleteInvoice: builder.mutation({
            query: (id) => ({
                url: `${INVOICE_API}/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: ["Invoice"],
            async onQueryStarted(arg, {dispatch}){
              invalidateTagsHelperInvoice(dispatch)
            }
        }),
    }),
});

export const {
    useGetInvoiceQuery,
    useGetInvoiceByIdQuery,
    useAddInvoiceMutation,
    useUpdateInvoiceMutation,
    useDeleteInvoiceMutation,
    useUpdateManyInvoiceMutation
} = InvoiceFormApi;

export default InvoiceFormApi;
