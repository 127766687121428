import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  DateInput,
  DisabledInput,
  DropdownInput,
  TextInput,
  CheckBox,
  LeedFormDropdownInput,
} from "../../../Inputs";
import { useDispatch } from "react-redux";
import { push } from "../../../redux/features/opentabs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSave,
  faTimes,
  faQuoteRight,
  faProjectDiagram,
  faDollarSign,
  faFileInvoiceDollar,
} from "@fortawesome/free-solid-svg-icons";
import { useGetInvoiceQuery } from "../../../redux/services/InvoiceService";
import { getDateFromDateTime } from "../../../Utils/helper";
import FormHeader from "../../../Basic/components/FormHeader";
import secureLocalStorage from "react-secure-storage";
import { toast } from "react-toastify";
import {
  useAddLeadMutation,
  useDeleteLeadMutation,
  useGetLeadByIdQuery,
  useUpdateLeadMutation,
} from "../../../redux/services/LeadFormService";
import {
  useGetPartyByIdQuery,
} from "../../../redux/services/PartyMasterService";
import PartySearchComponent from "./PartySearchComponent";
import moment from "moment";
import InvoiceTableModal from "./InvoiceTableModal";
import ContactPersonSearch from "./ContactPersonSearch";
import {
  leadCancelReason,
  maintenanceProject,
  FormType,
  reference,
} from "../../../Utils/DropdownData";
import { PLUS } from "../../../icons";
import useInvalidateTags from "../../../CustomHooks/useInvalidateTags";
import LeadAttachementForm from "./LeadAttachementForm";
import { useGetUserByIdQuery } from "../../../redux/services/UsersMasterService";
const MODEL = "Lead Form";

const LeadPageForm = ({
  isMaintenance,
  setIsMaintenance,
  setMaintenanceDocId,
  maintenanceDocId,
  setSearchValue,
  id,
  setId,
  setLeadPageOpen,
  onClick,
  searchValue,
  setReadOnly,
  readOnly,
  getNextDocId,
  docId,
  setDocId,
  refreshPage,
  setRefreshPage,
}) => {
  const [form, setForm] = useState(true);

  const today = new Date();

  const [name, setName] = useState("");
  const [dueDate, setDueDate] = useState();
  const [contact, setContact] = useState();
  const [contactPersonName, setContactPersonName] = useState("");
  const [date, setDate] = useState(
    getDateFromDateTime(moment(today).format("YYYY-MM-DD"))
  );
  const [maintenanceDate, setMaintenanceDate] = useState(
    getDateFromDateTime(moment(today).format("YYYY-MM-DD"))
  );
  const [active, setActive] = useState(true);
  const [isMaintenanceCompleted, setIsMaintenanceCompleted] = useState(true);
  const [nonGridUser, setNonGridUser] = useState();


  const childRecord = useRef(0);
  const [workDescription, setWorkDescription] = useState("");
  const [comments, setComments] = useState([]);
  const [clientId, setClientId] = useState("");
  const dispatch = useDispatch();
  const [referenceName, setReferenceName] = useState("CLIENT.REF");
  const [reason, setReason] = useState("");
  const [newOrOldProject, setNewOrOldProject] = useState("");
  const [search, setSearch] = useState("");
  const [location, setLocation] = useState();
  const [projectCount, setProjectCount] = useState("");
  const [quoteCount, setQuoteCount] = useState("");
  const [paymentCount, setPaymentCount] = useState("");
  const [projectName, setProjectName] = useState("");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const companyId = secureLocalStorage.getItem(
    sessionStorage.getItem("sessionId") + "userCompanyId"
  );
  const [uploadedFiles, setUploadedFiles] = useState([]);

  const params = { companyId };
  const branchId = secureLocalStorage.getItem(
    sessionStorage.getItem("sessionId") + "currentBranchId"
  );

  const userId = secureLocalStorage.getItem(
    sessionStorage.getItem("sessionId") + "userId"
  )

  const {
    data: userData,
    isFetching: isSingleUserFetching,
    isLoading: isSingleUserLoading,
  } = useGetUserByIdQuery(userId, { skip: !userId });

  const {
    data: singleData,
    isFetching: isSingleFetching,
    isLoading: isSingleLoading,
  } = useGetLeadByIdQuery(id, { skip: !id });
  const [addData] = useAddLeadMutation();
  const [updateData] = useUpdateLeadMutation();
  const [removeData] = useDeleteLeadMutation();

  const {
    data: singlePartyData,
    isFetching: isSinglepartyFetching,
    isLoading: isSinglePartyLoading,
  } = useGetPartyByIdQuery(clientId, { skip: !clientId });

  // const getNextDocId = useCallback(() => {
  //     if (id || isLoading || isFetching) return
  //     if (allData?.nextDocId) {
  //         setDocId(allData.nextDocId)
  //     }
  // }, [allData, isLoading, isFetching, id])

  // useEffect(getNextDocId, [getNextDocId])



  const syncFormWithDb = useCallback(
    (data) => {
      if (id) setReadOnly(true);
      else {
        setReadOnly(false);
      }
      if (data?.docId) {
        setDocId(data?.docId);
      }
      if (data?.maintenanceDocId && data?.isMaintenance) {
        setMaintenanceDocId(data?.maintenanceDocId);
      }
      setIsMaintenanceCompleted(
        data?.isMaintenanceCompleted ? data?.isMaintenanceCompleted : false
      );
      setNewOrOldProject(data?.newOrOldProject ? data?.newOrOldProject : "");
      setIsMaintenance(data?.isMaintenance ? data?.isMaintenance : false);
      setName(data?.name ? data.name : "");
      setDate(
        data?.createdAt
          ? moment.utc(data.createdAt).format("YYYY-MM-DD")
          : moment.utc(today).format("YYYY-MM-DD")
      );
      setLocation(data?.location ? data.location : "");
      setContact(data?.contact ? data.contact : "");
      setContactPersonName(
        data?.contactPersonName ? data?.contactPersonName : ""
      );
      setActive(id ? (data?.active ? data.active : false) : true);
      setClientId(data?.clientId ? data?.clientId : "");
      setDueDate(id ? data?.dueDate ? moment.utc(data?.dueDate).format("YYYY-MM-DD") : "" : getDateFromDateTime(moment(today).format("YYYY-MM-DD")));

      setComments(data?.comments ? data?.comments : "");
      setWorkDescription(data?.workDescription ? data.workDescription : "");
      setReferenceName(
        data?.referenceName ? data?.referenceName : "CLIENT.REF"
      );
      setReason(data?.reason ? data?.reason : "");
      setProjectName(data?.projectName ? data?.projectName : "");
      setNonGridUser(data?.nonGridUser ? data?.nonGridUser : userData?.data?.username)

      childRecord.current = data?.childRecord ? data?.childRecord : 0;
    },
    [id]
  );

  useEffect(() => {
    syncFormWithDb(singleData?.data);
  }, [isSingleFetching, isSingleLoading, id, syncFormWithDb, singleData]);

  const data = {
    nonGridUser,

    name,
    active,
    location,
    branchId,
    clientId,
    isClient: true,
    referenceName,
    reason,
    newOrOldProject,
    isMaintenanceCompleted,
    companyId,
    id,
    workDescription,
    // dueDate: getDateFromDateTime(moment(today).format("YYYY-MM-DD")),
    dueDate,
    contactPersonName,
    contact,
    comments,
    isMaintenance,
    projectName,
  };

  const validateData = (data) => {
    if (data.workDescription && data?.clientId) {
      return true;
    }
    return false;
  };

  const onNew = () => {
    getNextDocId();
    setId("");
    syncFormWithDb(undefined);
    setReadOnly(false);
    setSearchValue("");
    setContact("");
  };

  const [invalidateTagsDispatch] = useInvalidateTags();



  const handleSubmitCustom = async (callback, data, text) => {
    try {
      let returnData = await callback(data).unwrap();
      if (returnData.statusCode === 0) {
        setId(returnData?.data?.id);
        // syncFormWithDb(undefined)
        toast.success(text + "Successfully", {
          position: toast.POSITION.TOP_CENTER,
        });
      } else {
        toast.error(returnData?.message);
      }
      invalidateTagsDispatch()
    } catch (error) {
      console.log("handle");
    }
  };

  const saveData = () => {
    if (!validateData(data)) {
      toast.info("Please fill all required fields...!", {
        position: "top-center",
      });
      return;
    }
    if (!window.confirm("Are you sure save the details ...?")) {
      return;
    }
    if (id) {
      handleSubmitCustom(updateData, data, "Updated");
    } else {
      handleSubmitCustom(addData, data, "Added");
    }
  };

  const deleteData = async () => {
    if (id) {
      if (!window.confirm("Are you sure to delete...?")) {
        return;
      }
      try {
        await removeData(id).unwrap();
        setId("");
        invalidateTagsDispatch()
        toast.success("Deleted Successfully");
      } catch (error) {
        toast.error("something went wrong");
      }
    }
  };

  const handleKeyDown = (event) => {
    let charCode = String.fromCharCode(event.which).toLowerCase();
    if ((event.ctrlKey || event.metaKey) && charCode === "s") {
      event.preventDefault();
      saveData();
    }
  };

  useEffect(() => {
    if (!readOnly) {
      setDueDate(moment.utc(today).format("YYYY-MM-DD"));
    }
    return;
  }, [readOnly]);

  useEffect(() => {
    if (id) return
    setNonGridUser(userData?.data?.username);

  }, [userData, isSingleUserFetching, isSingleUserLoading])




  useEffect(() => {
    if (!clientId) return;

    if (id) return;

    if (
      singlePartyData?.data?.contactPersonName &&
      singlePartyData?.data?.contactMobile
    ) {
      setContact(singlePartyData?.data?.contactMobile);
      setContactPersonName(singlePartyData?.data?.contactPersonName);
      setLocation(singlePartyData?.data?.address);
    } else {
      setContactPersonName(singlePartyData?.data?.contactPersonName);
      setLocation(singlePartyData?.data?.address);
      setContact(
        singlePartyData?.data?.contactDetails?.find(
          (val) => val.contactPersonName === contactPersonName
        )?.mobileNo || singlePartyData?.data?.contactMobile
      );
    }
  }, [setContact, clientId, singlePartyData, setLocation]);

  useEffect(() => {
    if (id && readOnly) return;

    setContact(
      singlePartyData?.data?.contactDetails?.find(
        (val) => val.contactPersonName === contactPersonName
      )?.mobileNo
    );
  }, [contactPersonName, setContact]);

  useEffect(() => {
    if (id) return;

    let validDate = new Date();
    validDate.setDate(validDate.getDate() + 0);
    setDueDate(moment(validDate).format("YYYY-MM-DD"));
  }, [setDueDate, id]);


  function addNewComments() {
    setComments((prev) => [...prev, { log: "", date: today, gridUser: userData?.data?.username }]);
  }
  const projectOpen = () => {
    dispatch(
      push({
        name: "PROJECT",
        projectId: singleData?.data?.Quotes?.projectId
          ? singleData?.data?.Quotes?.projectId
          : "new",
        projectForm: true,
      })
    );
  };

  const quoteOpen = () => {
    let quoteId;
    let projectId;

    if (singleData?.data?.Quotes?.id) {
      projectId = singleData?.data?.Quotes?.id;
      quoteId = undefined;
    } else {
      quoteId = singleData?.data?.id;
      projectId = "new";
    }

    dispatch(
      push({
        name: "QUOTES",
        projectId: projectId,
        quoteId: quoteId,
        projectForm: true,
      })
    );
  };

  const paymentOpen = () => {
    dispatch(
      push({
        name: "PROJECT PAYMENTS",
        projectId: singleData?.data?.Quotes?.projectId
          ? singleData?.data?.Quotes?.projectId
          : "new",
        projectForm: true,
      })
    );
  };

  const expenseOpen = () => {
    dispatch(
      push({
        name: "PROJECT EXPENSE",
        projectId: singleData?.data?.Quotes?.projectId
          ? singleData?.data?.Quotes?.projectId
          : "new",
        projectForm: true,
      })
    );
  };

  const ProjectId = singleData?.data?.Quotes?.Project?.id ?? "";

  const {
    data: InvoiceData,
  } = useGetInvoiceQuery({
    params: { ProjectId, branchId },
  });


  return (
    <div className="md:items-start md:justify-items-center grid h-full bg-amber-50 overflow-y-scroll">
      <div className="flex flex-col frame w-full h-full ">
        <FormHeader onNew={onNew} model={MODEL} setReadOnly={setReadOnly} />


        <div className="leedForm">
          <LeedFormDropdownInput
            name="Form Type"
            options={FormType}
            value={isMaintenance}
            setValue={setIsMaintenance}
            readOnly={id}
            disabled={id}
          /></div>{console.log(isMaintenance, "isMaintenance")}
        <div className="grid grid-cols-3 gap-y-4 mt-4 w-full px-5">

          {isMaintenance && (
            <>
              <DropdownInput
                name="New/Previous"
                options={maintenanceProject}
                value={newOrOldProject}
                setValue={setNewOrOldProject}
                readOnly={readOnly}
                disabled={childRecord.current > 0}
              />
              <CheckBox
                name="Is Maintenance Completed"
                value={isMaintenanceCompleted}
                setValue={setIsMaintenanceCompleted}
                readOnly={readOnly}
              />
            </>
          )}
        </div>

        <div className="grid grid-cols-4 gap-4 mt-4 w-full px-5">
          {isMaintenance ? (
            <>
              <DisabledInput
                name="Maintenance No:"
                value={maintenanceDocId}
                required={true}
                readOnly={readOnly}
              />
              <DisabledInput
                name="Maintenance Date"
                value={maintenanceDate}
                type="date"
                required={true}
                readOnly={readOnly}
              />
            </>
          ) : (
            <>
              <DisabledInput
                name="Lead No"
                value={docId}
                required={true}
                readOnly={readOnly}
              />
              <DisabledInput
                name="Lead Date"
                value={date}
                type="date"
                required={true}
                readOnly={readOnly}
              />
            </>
          )}

          <PartySearchComponent
            setPartyId={setClientId}
            partyId={clientId}
            name="Client"
            readOnly={readOnly}
            id={id}
          />
          <ContactPersonSearch
            setContactPersonName={setContactPersonName}
            contactPersonName={contactPersonName}
            name="Con. Person Name"
            clientId={clientId}
            setContact={setContact}
            search={search}
            setSearch={setSearch}
            readOnly={readOnly}
          />
        </div>
        <div className="grid grid-cols-4 gap-4  w-full px-5">
          <div className="col-span-1 flex flex-col ">
            <label className="md:text-start text-sm p-1 flex">Reference</label>
            <select
              disabled={readOnly}
              className="input-field focus:outline-none md:col-span-2 border-gray-500 border rounded p-1 bg-white"
              value={referenceName}
              onChange={(e) => setReferenceName(e.target.value)}
            >
              {reference.map((option, index) => (
                <option key={index} value={option.value}>
                  {option.show}
                </option>
              ))}
            </select>
          </div>
          <div className="col-span-1 ">
            <TextInput
              name="Phone"
              type="number"
              value={contact}
              setValue={setContact}
              readOnly={readOnly}
            />
          </div>
          <div className="col-span-1 ">
            <TextInput
              name="Address"
              type="text"
              value={location}
              setValue={setLocation}
              readOnly={readOnly}
              disabled={childRecord.current > 0}
            />
          </div>
          <div className="col-span-1">
            <DateInput
              name="Follow-Up Date"
              value={dueDate}
              setValue={setDueDate}
              required={true}
              readOnly={readOnly}
            />
          </div>
        </div>

        <div className="grid grid-cols-4 gap-4  w-full px-5">
          <div className="col-span-2">
            <div className="flex flex-col pb-4  text-xs ">
              <label className="md:text-start flex text-sm pb-1">
                Description<span className="text-red-600">*</span>
              </label>
              <textarea
                readOnly={readOnly}
                className="focus:outline-none md:col-span-2 border-gray-500 border rounded mt-2 md:mt-0"
                value={workDescription}
                onChange={(e) => setWorkDescription(e.target.value)}
              ></textarea>
            </div>
          </div>

          {/* <div className="col-span-1"> */}
          <TextInput
            name="Project Name"
            type="text"
            value={projectName}
            setValue={setProjectName}
            readOnly={readOnly}
            disabled={childRecord.current > 0}

          />
          {/* </div> */}

          <DisabledInput
            name="UserName"
            value={nonGridUser}
            required={true}
            readOnly={readOnly}
          />


        </div>

        {!singleData?.data?.Quotes?.projectId && (
          <div className="grid grid-cols-3 gap-y-4 mt-4 w-full px-5">
            <CheckBox
              name="Active"
              value={active}
              setValue={setActive}
              readOnly={readOnly}
            />
            {!active && (
              <DropdownInput
                name="Reason"
                options={leadCancelReason}
                value={reason}
                setValue={setReason}
                readOnly={readOnly}
                disabled={childRecord.current > 0}
              />
            )}
          </div>
        )}

        <div className="w-full grid grid-cols-1 mt-2  px-5">
          <div className="grid grid-cols-1 gap-4 p-1">
            <table className="border border-gray-300 text-sm table-auto w-full">
              <thead className="bg-gray-300 border border-gray-400">
                <tr>
                  <th className="py-1 px-3 w-10 text-left border border-gray-400">S.No</th>
                  <th className="py-1 px-3 w-32 text-left border border-gray-400">Date</th>
                  <th className="py-1 px-3 w-32 text-left border border-gray-400">User</th>
                  <th className="py-1 px-3 text-left border border-gray-400">Comments</th>
                  <th className="py-1 px-3 text-left w-20 border border-gray-400">File</th>

                  <th className="py-1 px-3 w-10 text-center">
                    <button
                      onClick={addNewComments}
                      className="text-green-500 hover:text-green-700 transition duration-150"
                    >
                      {PLUS}
                    </button>
                  </th>
                </tr>
              </thead>
              <tbody className="overflow-y-auto">
                {(comments ? comments : []).map((item, index) => (
                  <LeadAttachementForm leadId={id} key={index} item={item} index={index} readOnly={false} userName={userData?.data?.username || ""} />
                ))}
              </tbody>
            </table>
          </div>
        </div>


        <div className="flex align-center justify-center gap-x-10 pt-4 ">
          <button
            className="bg-blue-600 text-white rounded-md py-2 px-4 text-md w-32 flex items-center justify-center"
            onClick={saveData}
          >
            <FontAwesomeIcon icon={faSave} className="mr-2" /> Save
          </button>
          <button
            className="bg-red-600 text-white rounded-md py-2 px-4 text-md w-32 flex items-center justify-center"
            onClick={() => {
              onClick();
              setRefreshPage(false);
            }}
          >
            <FontAwesomeIcon icon={faTimes} className="mr-2" /> Close
          </button>
          <button
            className="bg-yellow-600 text-white rounded-md py-2 px-4 text-md w-32 flex items-center justify-center"
            onClick={quoteOpen}
          >
            <FontAwesomeIcon icon={faQuoteRight} className="mr-2" />{" "}
            {singleData?.data?.Quotes?.id ? "Quote" : "NewQuote"}
          </button>
          {singleData?.data?.Quotes?.projectId && (
            <button
              className="bg-green-600 text-white rounded-md py-2 px-4 text-md w-32 flex items-center justify-center"
              onClick={projectOpen}
            >
              <FontAwesomeIcon icon={faProjectDiagram} className="mr-2" />{" "}
              Project
            </button>
          )}
          {(singleData?.data?.Quotes?.Project?.isProjectCompleted ||
            singleData?.data?.Quotes?.projectId) && (
              <button
                className="bg-violet-600 text-white rounded-md py-2 px-4 text-md w-32 flex items-center justify-center"
                onClick={paymentOpen}
              >
                <FontAwesomeIcon icon={faDollarSign} className="mr-2" /> Payments
              </button>
            )}
          {(singleData?.data?.Quotes?.Project?.isProjectCompleted ||
            singleData?.data?.Quotes?.projectId) && (
              <button
                className="bg-orange-600 text-white rounded-md py-2 px-4 text-md w-32 flex items-center justify-center"
                onClick={expenseOpen}
              >
                <FontAwesomeIcon icon={faFileInvoiceDollar} className="mr-2" />{" "}
                Expense
              </button>
            )}

          {(singleData?.data?.Quotes?.Project?.isProjectCompleted ||
            singleData?.data?.Quotes?.projectId) && (
              <button
                className="bg-gray-500 text-white rounded-md py-2 px-4 text-md w-32 flex 
                        items-center justify-center"
                onClick={() => setModalIsOpen(true)}
              >
                <FontAwesomeIcon icon={faFileInvoiceDollar} className="mr-2" />{" "}
                Invoice
              </button>
            )}
        </div>

        <InvoiceTableModal
          isOpen={modalIsOpen}
          onRequestClose={() => setModalIsOpen(false)}
          invoiceData={InvoiceData}
        />
      </div>
    </div>
  );
};

export default LeadPageForm;
