import { faTrashCan, faUserPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { paymentMethods } from '../../../Utils/DropdownData';

const LabourExpense = ({ userDate, setUserDate, setAliasName, aliasName, setFoodCost, foodCost, transportCost, setTransportCost, readOnly, expenseItems, setExpenseItems, childRecord }) => {

    function handleInputChange(value, index, field) {
        const newBlend = structuredClone(expenseItems);

        newBlend[index][field] = value;
        if (field === "isCompany") {
            newBlend[index][field] = value;
            newBlend[index]["isPersonal"] = !value;
        }
        if (field === "isPersonal") {
            newBlend[index][field] = value;
            newBlend[index]["isCompany"] = !value;
        }

        setExpenseItems(newBlend);
    }

    function removeItem(index) {
        setExpenseItems(labourExpense => {
            return labourExpense.filter((_, i) => parseInt(i) !== parseInt(index))
        });
    }

    return (
        <>
            <div className='grid grid-cols-4 gap-4'>
                <div className=' items-center'>
                    <label className='text-sm font-medium'>Con. Name</label>
                    <input
                        type='text'
                        className='focus:outline-none border-gray-300 border rounded w-48 py-1  text-sm'
                        value={aliasName}
                        onChange={(e) => { setAliasName(e.target.value) }}
                        readOnly={readOnly}
                    />
                </div>
                <div className=' items-center'>
                    <label className='text-sm font-medium'>Transport Cost</label>
                    <input
                        type='number'
                        className='focus:outline-none border-gray-300 border rounded w-48 py-1  text-sm'
                        value={transportCost}
                        onChange={(e) => { setTransportCost(e.target.value) }}
                        readOnly={readOnly}
                    />
                </div>
                <div className=' items-center'>
                    <label className='text-sm font-medium ml-2'>Food Cost</label>
                    <input
                        type='number'
                        className='focus:outline-none border-gray-300 border rounded w-48 py-1 ml-2 text-sm'
                        value={foodCost}
                        onChange={(e) => { setFoodCost(e.target.value) }}
                        readOnly={readOnly}
                    />
                </div>
                <div className="flex flex-col items-start gap-1 text-sm">
                    <label>Expense Date</label>
                    <input
                        type="date"
                        className="focus:outline-none border border-gray-500 rounded w-28 p-0.5"
                        value={userDate}
                        onChange={(e) => setUserDate(e.target.value)}
                        readOnly={readOnly}
                    />
                </div>
            </div>

            <div className='mt-6'>
                <table className='min-w-full border bg-white border-gray-300'>
                    <thead className='bg-gray-200'>
                        <tr>
                            <th className='border border-gray-300 text-sm p-2'>S.no</th>
                            <th className='border border-gray-300 text-sm p-2'>L.Count</th>
                            <th className='border border-gray-300 text-sm p-2'>No.Of.Shift/Sq.Ft</th>
                            <th className='border border-gray-300 text-sm p-2'>Rate</th>
                            <th className='border border-gray-300 text-sm p-2'>Method</th>
                            <th className='border border-gray-300 text-sm p-2'>CollectBy/IsCompany</th>
                            <th className='border border-gray-300 text-sm p-2'>Given.By/IsPersonal</th>
                            <th className='border border-gray-300 text-sm p-2'>Total</th>
                            <th className='border border-gray-300 text-sm p-2'>
                                <button
                                    type='button'
                                    className='text-green-700 p-1'
                                    onClick={() => {
                                        let newExpenseitems = [...expenseItems];
                                        newExpenseitems.push({});
                                        setExpenseItems(newExpenseitems);
                                    }}>
                                    <FontAwesomeIcon icon={faUserPlus} />
                                </button>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {expenseItems?.map((item, index) => (
                            <tr key={index} className='text-center'>
                                <td className='border border-gray-300 text-xs p-2'>{index + 1}</td>
                                <td className='border border-gray-300 text-xs p-2'>
                                    <input
                                        className='w-full p-1'
                                        type='text'
                                        value={item.count}
                                        onChange={(e) => handleInputChange(e.target.value, index, "count")}
                                        required={true}
                                        readOnly={readOnly}
                                        disabled={childRecord.current > 0}
                                    />
                                </td>
                                <td className='border border-gray-300 text-xs p-2'>
                                    <input
                                        className='w-full p-1'
                                        type='text'
                                        value={item.noOfShift}
                                        onChange={(e) => handleInputChange(e.target.value, index, "noOfShift")}
                                        required={true}
                                        readOnly={readOnly}
                                        disabled={childRecord.current > 0}
                                    />
                                </td>
                                <td className='border border-gray-300 text-xs p-2'>
                                    <input
                                        className='w-full p-1'
                                        type='number'
                                        value={item.rate}
                                        onChange={(e) => handleInputChange(e.target.value, index, "rate")}
                                        required={true}
                                        readOnly={readOnly}
                                        disabled={childRecord.current > 0}
                                    />
                                </td>
                                <td className='border border-gray-300 text-xs p-2'>
                                    <select
                                        disabled={readOnly}
                                        className='text-left w-full p-1 border border-gray-300 rounded'
                                        value={item.paymentMethod}
                                        onChange={(e) => handleInputChange(e.target.value, index, "paymentMethod")}
                                    >
                                        <option hidden></option>
                                        {paymentMethods?.map((data) => (
                                            <option value={data.value} key={data.show}>
                                                {data.show}
                                            </option>
                                        ))}
                                    </select>
                                </td>
                                {(item?.paymentMethod !== "CASH" && item?.paymentMethod !== "") && (
                                    <>
                                        <td className='border border-gray-300 text-xs p-2'>
                                            <input
                                                type='radio'
                                                name={`isAccount-${index}`}
                                                className='mx-2'
                                                checked={item?.isCompany}
                                                onChange={(e) => handleInputChange(e.target.checked, index, "isCompany")}
                                                disabled={readOnly}
                                            />
                                        </td>
                                        <td className='border border-gray-300 text-xs p-2'>
                                            <input
                                                type='radio'
                                                name={`isAccount-${index}`}
                                                className='mx-2'
                                                checked={item?.isPersonal}
                                                onChange={(e) => handleInputChange(e.target.checked, index, "isPersonal")}
                                                disabled={readOnly}
                                            />
                                        </td>
                                    </>
                                )}
                                {(item?.paymentMethod === "CASH") && (
                                    <>
                                        <td className='border border-gray-300 text-xs p-2'>
                                            <input
                                                type='text'
                                                className='w-full p-1 border border-gray-300 rounded'
                                                value={item?.collectBy}
                                                onChange={(e) => handleInputChange(e.target.value, index, "collectBy")}
                                                readOnly={readOnly}
                                            />
                                        </td>
                                        <td className='border border-gray-300 text-xs p-2'>
                                            <input
                                                type='text'
                                                className='w-full p-1 border border-gray-300 rounded'
                                                value={item?.givenBy}
                                                onChange={(e) => handleInputChange(e.target.value, index, "givenBy")}
                                                readOnly={readOnly}
                                            />
                                        </td>
                                    </>
                                )}
                                <td className='border border-gray-300 text-xs p-2'>
                                    {parseFloat(parseFloat(item.noOfShift) * parseFloat(item.rate)).toFixed(3) || 0}
                                </td>
                                <td className='border border-gray-300 text-xs p-2'>
                                    <button
                                        type='button'
                                        onClick={() => removeItem(index)}
                                        className='text-red-600'
                                    >
                                        <FontAwesomeIcon icon={faTrashCan} />
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </>
    )
}

export default LabourExpense
