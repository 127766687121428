import React from 'react'
import { DropdownInput } from '../../Inputs'
import { dropDownListObject } from '../../Utils/contructObject'
import { DisabledInput } from '../../Inputs'

const QuoteTypeNewOrExistingProject = ({ value, setValue, readOnly }) => {
    const data = [
        {
            name: "New Project",
            id: "New"
        },
        {
            name: "Existing Project",
            id: "Existing"
        }
    ]
    return (
        <>
         {readOnly ?  <DisabledInput
        name="Quote For"
        value={value}
        required={true}
        readOnly={false}
        className="w-full"
      /> :(<DropdownInput name={"Quote For"} options={dropDownListObject(data, "name", "id")} value={value} setValue={setValue} readOnly={readOnly} />)  }
   
        </>
       )
}

export default QuoteTypeNewOrExistingProject
