import React, { useEffect, useState } from 'react';
import secureLocalStorage from 'react-secure-storage';
import {
  useGetEmployeeQuery
} from '../../../redux/services/EmployeeMasterService';
import FormHeader from '../../../Basic/components/FormHeader';
import { toast } from "react-toastify";
import { DisabledInput, DateInput, DropdownInput } from '../../../Inputs';
import { employeeTypes } from '../../../Utils/DropdownData';
import { TablePagination } from '@mui/material';

const MODEL = "Employee Attendance";

export default function Form() {
  const [form, setForm] = useState(false);
  const [readOnly, setReadOnly] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [employeeType, setEmployeeType] = useState("");

  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
  };

  const [date, setDate] = useState(getCurrentDate());
  const [id, setId] = useState("");
  const [employeeId, setEmployeeId] = useState("");
  const [month, setMonth] = useState(new Date().toISOString().slice(0, 7));
  const [workingDays, setWorkingDays] = useState(0);
  const [attendanceData, setAttendanceData] = useState([]);

  const calculateWorkingDays = (month) => {
    let date = new Date(month);
    let year = date.getFullYear();
    let monthIndex = date.getMonth();
    let totalDays = new Date(year, monthIndex + 1, 0).getDate();
    let workingDaysCount = 0;

    for (let day = 1; day <= totalDays; day++) {
      let currentDate = new Date(year, monthIndex, day);
      if (currentDate.getDay() !== 0) {
        workingDaysCount++;
      }
    }
    return workingDaysCount;
  };

  const params = {
    companyId: secureLocalStorage.getItem(
      sessionStorage.getItem("sessionId") + "userCompanyId"
    ),
  };

  const { data: empData, isLoading, isFetching } = useGetEmployeeQuery({ params, searchParams: searchValue });
  console.log(empData,"empData")

  useEffect(() => {
    setWorkingDays(calculateWorkingDays(month));
  }, [month]);

  const handleMonthChange = (value) => {
    setMonth(value);
    setWorkingDays(calculateWorkingDays(value));
  };

  const saveData = () => {
    toast.success("Attendance data saved successfully!");
  };

  const onNew = () => {
    setId("");
    setReadOnly(false);
    setForm(true);
  };

  // Updated Table Headers
  const patientHeaders = [
    "Employee Name", 
    "Total Present Days", 
    "Today's Working Hours", 
    "Overtime Today", 
    "Total Overtime This Month"
  ];

  return (
    <div className="md:items-start md:justify-items-center grid h-full bg-theme">
      <div className="flex flex-col frame w-full h-full">
        <FormHeader onNew={onNew} onClose={() => setForm(false)} model={MODEL} saveData={saveData} setReadOnly={setReadOnly} />
        <div className="flex-1 grid grid-cols-1 gap-x-2 overflow-clip">
          <div className="col-span-4 grid md:grid-cols-1 border overflow-auto">
            <div className="mr-1 md:ml-2">
              <fieldset className="frame my-1">
                <legend className="sub-heading">Employee Attendance Info</legend>
                <div className="grid grid-cols-6 gap-4 my-2">
                  <DisabledInput name="Trans. Date" value={date} type={"Date"} required={true} readOnly={readOnly} />
                  <DropdownInput
                    name="Employee Type"
                    className="text-sm"
                    options={employeeTypes}
                    value={employeeType}
                    setValue={setEmployeeType}
                    required={true}
                    readOnly={readOnly}
                  />
                  <DateInput
                    name="Month"
                    value={month}
                    setValue={handleMonthChange}
                    required={true}
                    readOnly={readOnly}
                    type="month"
                  />
                  <DisabledInput
                    name="Number of Working Days"
                    value={workingDays}
                    readOnly={true}
                  />
                  <DisabledInput
                    name="Number of Present"
                    value={'23'}
                    readOnly={true}
                  />
                  <DisabledInput
                    name="Number of Absent"
                    value={'4'}
                    readOnly={true}
                  />
                </div>
              </fieldset>
              <div className="w-full h-full">
                <div className="grid grid-cols-1 lg:grid-cols-4 gap-4">
                  <div className="lg:col-span-3">
                    <table className="w-full text-sm table-auto text-left border-collapse border border-gray-200">
                      <thead>
                        <tr className="bg-gray-200 uppercase text-gray-700">
                          {patientHeaders.map((header, index) => (
                            <th
                              key={index}
                              className="border border-gray-300 p-2 text-sm font-semibold"
                            >
                              {header}
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody className="bg-white">
                        {empData?.data?.map((emp, index) => (
                          <tr
                            key={index}
                            className="border-b hover:bg-gray-200 transition-colors duration-200 text-xs cursor-pointer even:bg-gray-100"
                            onClick={() => setId(emp.id)}
                          >
                            <td className="p-2 border border-gray-300">
                              {emp?.Patient?.name} {/* Employee Name */}
                            </td>
                            <td className="p-2 border border-gray-300">
                              {emp?.totalPresentDays} {/* Total Present Days */}
                            </td>
                            <td className="p-2 border border-gray-300">
                              {emp?.todayWorkingHours} {/* Today's Working Hours */}
                            </td>
                            <td className="p-2 border border-gray-300">
                              {emp?.overtimeToday} {/* Overtime Today */}
                            </td>
                            <td className="p-2 border border-gray-300">
                              {emp?.totalOvertimeThisMonth} {/* Total Overtime This Month */}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <div className='flex items-center justify-end'>
                      <TablePagination
                        component="div"
                        count={empData?.totalCount || 0}
                        page={0}
                        onPageChange={() => {}}
                        rowsPerPage={10}
                        onRowsPerPageChange={() => {}}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
