import React, { useEffect, useState } from 'react';
import useOutsideClick from '../../../../src/CustomHooks/handleOutsideClick';
import { useGetPartyByIdQuery } from '../../../redux/services/PartyMasterService';
import { Loader } from '../../../Basic/components';

const ShippingAddressComponent = ({ shippingAddress, setShippingAddress, name = null, clientId, isDifferAddress, billingId }) => {
    const [isListShow, setIsListShow] = useState(false);
    const inputRef = useOutsideClick(() => { setIsListShow(false); });
    const [filteredPages, setFilteredPages] = useState([]);
    const [search, setSearch] = useState("");

    const { data: singlePartyData } = useGetPartyByIdQuery(isDifferAddress ? billingId : clientId, { skip: (!clientId) });

    useEffect(() => {
        if (!singlePartyData) return;
        setFilteredPages(singlePartyData?.data?.ShippingAddress || []);
    }, [singlePartyData]);


    return (
        <div id='pageSearch' className='relative flex flex-col text-black z-10 w-full' ref={inputRef}>
            <div className='grid grid-cols-1 md:grid-cols-1 items-center md:my-0.5 md:px-1 data gap-1'>
                {name && (
                    <label className='md:text-start font-semibold text-xs'>
                        {name} <span className='text-red-600 '>*</span>
                    </label>
                )}
                {isListShow ? (
                    <div className='col-span-2'>
                        <input
                            type="text"
                            className='input-field focus:outline-none border-gray-500 border rounded w-full p-1 text-xs'
                            value={search}
                            onChange={(e) => { setSearch(e.target.value.toUpperCase()); }}
                            onFocus={() => { setIsListShow(true); }}
                        />
                    </div>
                ) : (
                    <div className='col-span-2'>
                        <input
                            type="text"
                            className='input-field focus:outline-none border-gray-500 border rounded w-full p-1 text-xs'
                            value={shippingAddress}
                            onFocus={() => { setIsListShow(true); }}
                        />
                    </div>
                )}
                {isListShow && (
                    <ul className='absolute max-h-[300px] overflow-auto top-12 bg-gray-200 w-full mt-1 border border-gray-300 rounded-md'>
                        {filteredPages.map((party) => (
                            <li
                                className='cursor-pointer hover:bg-blue-500 p-2'
                                key={party.address}
                                onClick={() => {
                                    setShippingAddress(party.address);
                                    setSearch("");
                                    setIsListShow(false);
                                }}
                                onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                        setShippingAddress(party.address);
                                        setSearch("");
                                        setIsListShow(false);
                                    }
                                }}
                                tabIndex={0}
                            >
                                <pre>{party.address}</pre>
                            </li>
                        ))}
                    </ul>
                )}
            </div>
        </div>
    );
};

export default ShippingAddressComponent;
