import React from 'react';
import { Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer';
import moment from 'moment';
import tw from '../../../Utils/tailwind-react-pdf';

const styles = StyleSheet.create({
  page: {
    padding: 30,
    fontSize: 12,
    fontFamily: 'Helvetica',
    color: '#333',
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 20,
    borderBottomWidth: 1,
    borderBottomColor: '#E0E0E0',
    paddingBottom: 10,
  },
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 15,
    paddingHorizontal: 15,
    width: '100%',
  },
  terms: {
    marginTop: 20,
    padding: 10,
    borderTopWidth: 1,
    borderTopColor: '#E0E0E0',
  },
  termItem: {
    marginBottom: 5,
  },
  leftColumn: {
    flex: 1,
    paddingRight: 10,
  },
  rightColumn: {
    flex: 1,
    paddingLeft: 10,
  },
  section: {
    marginBottom: 10,
  },
  label: {
    fontWeight: 'bold',
    fontSize: 12,
    color: '#555',
    marginBottom: 2,
  },
  value: {
    fontSize: 12,
    color: '#000',
    marginBottom: 5,
  },
  notes: {
    padding: 10,
    backgroundColor: '#f9f9f9',
    borderRadius: 5,
    marginBottom: 20,
    marginHorizontal: 15,
  },

    signatureSection: {
      position: 'absolute',
      bottom: 10, 
      left: 0,
      right: 0,
      flexDirection: 'row',
      justifyContent: 'space-between',
      paddingHorizontal: 15,
    },
   

  
  signatureLine: {
    marginTop: 30,
    borderTopWidth: 1,
    borderTopColor: '#000',
    width: '30%',
    textAlign: 'center',
    paddingTop: 5,
    color: '#555',
  },
  terms: {
    marginTop: 20,
    padding: 10,
    borderTopWidth: 1,
    borderTopColor: '#E0E0E0',
  },
  termItem: {
    marginBottom: 5,
  },
  table: {
    display: "table",
    width: "auto",
    margin: "10px 0",
  },
  tableRow: {
    flexDirection: "row",
  },
  tableCol: {
    width: "25%",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#000",
 
  },
  tableCol1: {
    width: "25%",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#000",
    backgroundColor: "#0178D0",
    color: "white"
  },
  tableCell: {
    margin: "auto",
    marginTop: 5,
    fontSize: 10,
  },
  header: {
    fontSize: 12,
    fontWeight: "bold",
    marginBottom: 10,
  },
});

const PrintFormat = ({ data, clientName, filteredPayment }) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <View fixed style={tw("w-full border-b border-gray-300")}>
        <View style={tw("flex flex-row justify-between items-center p-1")}>
          <View style={tw("w-2/8")}>
            <Image source={require("../../../assets/uniass.jpeg")} style={{ width: 55, height: 55 }} />
          </View>
          <View style={tw("w-1/2  rounded-md text-center p-2")}>
            <Text style={{ fontSize: 16 }}>Payment Detail</Text>
          </View>
          <View style={tw("w-1/4")}>
            <Text style={styles.label}>Date: {moment(data.createdAt).format('MMMM Do YYYY')}</Text>
          </View>
        </View>
      </View>

      <View fixed style={tw("w-full bg-white rounded-lg shadow-md mb-4 p-3")}>
  <View style={tw("flex flex-row justify-between border-b border-gray-300 pb-3")}>
    <View style={tw("flex flex-col space-y-1.5")}>
      <Text style={[tw("text-sky-800"), { fontSize: 18, fontWeight: "bold" }]}>Universal Associates</Text>
      <Text style={{ fontSize: 10 }}>148 Nataraj Layout, 15 Velamapalayam,</Text>
      <Text style={{ fontSize: 10 }}>Tiruppur, TN (33) 641652</Text>
      <Text style={{ fontSize: 10 }}>+919597639777</Text>
      <Text style={{ fontSize: 10 }}>1universalassociates@gmail.com</Text>
      <View style={tw("flex flex-row items-center gap-x-2")}>
        <Text style={[tw("font-bold text-gray-700"), { fontSize: 10 }]}>GSTIN:</Text>
        <Text style={{ fontSize: 10 }}>33BIIPS8122C1ZF</Text>
      </View>
      <View style={tw("flex flex-row items-center gap-x-2")}>
        <Text style={[tw("font-bold text-gray-700"), { fontSize: 10 }]}>Website:</Text>
        <Text style={{ fontSize: 10 }}>universalassociates.co.in</Text>
      </View>
      <View style={tw("flex flex-row items-center gap-x-2")}>
        <Text style={[tw("font-bold text-gray-700"), { fontSize: 10 }]}>Contact Name:</Text>
        <Text style={{ fontSize: 10 }}>Universal Associates</Text>
      </View>
    </View>

    <View style={tw("flex flex-col mt-3 space-y-1.5 w-1/2")}>
      <Text style={{ fontSize: 12 }}>
        Bank Name: <Text style={tw("text-sky-800")}>Karur Vysya Bank</Text>
      </Text>
      <Text style={{ fontSize: 12 }}>
        Account Number: <Text style={tw("text-sky-800")}>1779135000004779</Text>
      </Text>
      <Text style={{ fontSize: 12 }}>
        Branch Name: <Text style={tw("text-sky-800")}>Velampalayam</Text>
      </Text>
      <Text style={{ fontSize: 12 }}>
        IFSC Code: <Text style={tw("text-sky-800")}>KVBL0001779</Text>
      </Text>
    </View>
  </View>
</View>
<View style={styles.table}>
        <View style={styles.tableRow}>
          <View style={styles.tableCol1}>
            <Text style={styles.tableCell}>Doc ID</Text>
          </View>
          <View style={styles.tableCol1}>
            <Text style={styles.tableCell}>Amount</Text>
          </View>
          <View style={styles.tableCol1}>
            <Text style={styles.tableCell}>Payment Method</Text>
          </View>
          <View style={styles.tableCol1}>
            <Text style={styles.tableCell}>Handover To</Text>
          </View>
        </View>
        {filteredPayment.map((payment) => (
          <View style={styles.tableRow} key={payment.id}>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{payment.docId}</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{payment.amount}</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{payment.paymentMethod}</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{payment.handoverTo}</Text>
            </View>
          </View>
        ))}
        </View>

      <View style={styles.container}>
        <View style={styles.leftColumn}>
          <View style={styles.section}>
            <Text style={styles.label}>Client Name:</Text>
            <Text style={styles.value}>{clientName}</Text>
          </View>
          <View style={styles.section}>
            <Text style={styles.label}>Payment Method:</Text>
            <Text style={styles.value}>{data.paymentMethod}</Text>
          </View>
          <View style={styles.section}>
            <Text style={styles.label}>Collected By:</Text>
            <Text style={styles.value}>{data.collectBy}</Text>
          </View>
          <View style={styles.section}>
            <Text style={styles.label}>Project Amount:</Text>
            <Text style={styles.value}>{data.projectAmount}</Text>
          </View>
        </View>

        <View style={styles.rightColumn}>
          <View style={styles.section}>
            <Text style={styles.label}>Hand Over:</Text>
            <Text style={styles.value}>{data.handoverTo}</Text>
          </View>
          <View style={styles.section}>
            <Text style={styles.label}>Amount:</Text>
            <Text style={styles.value}>{data.amount}</Text>
          </View>
          <View style={styles.section}>
            <Text style={styles.label}>Already Received:</Text>
            <Text style={styles.value}>{data.alreadyPayment}</Text>
          </View>
          <View style={styles.section}>
            <Text style={styles.label}>Balance Amount:</Text>
            <Text style={styles.value}>
              {(parseFloat(data.projectAmount) || 0) - (parseFloat(data.alreadyPayment) || 0) - (parseFloat(data.amount) || 0)}
            </Text>
          </View>
        </View>
      </View>

      <View style={styles.notes}>
        <Text style={styles.label}>Notes:</Text>
        <Text style={styles.value}>{data.notes}</Text>
      </View>
      {/* <View style={styles.terms}>
        <Text style={styles.label}>Terms & Conditions:</Text>
        <Text style={styles.termItem}>50% Payment in advance.</Text>
        <Text style={styles.termItem}>25% Payment after 50% work finish.</Text>
        <Text style={styles.termItem}>15% Payment after 75% work finish.</Text>
        <Text style={styles.termItem}>10% After Finishing Work.</Text>
        <Text style={styles.termItem}>Note: For tax bills, an 18% amount is added for untaxed items.</Text>
        <Text style={styles.termItem}>** Actual quantity/area/weight(Including Wastage) is taken for final bill.</Text>
        <Text style={styles.termItem}>** Civil & Painting work is in the client's scope.</Text>
        <Text style={styles.termItem}>** Electrical & Plumbing are in the client's scope.</Text>
        <Text style={styles.termItem}>** Electricity for work will be supplied by the client.</Text>
        <Text style={styles.termItem}>** Ladder, Scaffolding & crane are in the client's scope.</Text>
        <Text style={styles.termItem}>** Make sure the site is suitable for working conditions in the client's scope.</Text>
        <Text style={styles.termItem}>** Chairs are in the client scope.</Text>
      </View> */}


      <View style={styles.signatureSection}>
        <Text style={styles.signatureLine}>Collected By</Text>
        <Text style={styles.signatureLine}>Handed Over By</Text>
        <Text style={styles.signatureLine}>Authorized By</Text>
      </View>
    </Page>
  </Document>
);

export default PrintFormat;
