import moment from 'moment'
import React, { useEffect } from 'react'
import { useState } from 'react'
import { DELETE, VIEW } from '../../../icons';
import { SaveButton } from '../../../Buttons';
import { useAddLeadAttachmentMutation, useDeleteLeadAttachmentMutation, useUpdateLeadAttachmentMutation } from '../../../redux/services/LeadFormService';
import Modal from "../../../UiComponents/Modal";
import { getImageUrlPath } from '../../../helper';
import { getDateFromDateTime } from '../../../Utils/helper';
import { toast } from 'react-toastify';

const LeadAttachementForm = ({ item, index, readOnly, leadId, dueDate, userName }) => {
    const [log, setLog] = useState('');
    const [gridUser, setGridUser] = useState(userName);
    const [date, setDate] = useState(getDateFromDateTime(new Date()));
    const [file, setFile] = useState('');
    const [previewFile, setPreviewFile] = useState(false);



    useEffect(() => {
        if (item.id) {
            setLog(item.log);
            setDate(item.date);
            setFile(item.filePath);
            setGridUser(item.gridUser)
        }

    }, [item])



    const [addLeadAttachment] = useAddLeadAttachmentMutation();
    const [deleteLeadAttachment] = useDeleteLeadAttachmentMutation();
    const [updateLeadAttachment] = useUpdateLeadAttachmentMutation();

    function saveComments() {
        const formData = new FormData();
        formData.append('log', log);
        formData.append('date', date);
        formData.append('gridUser', gridUser);
        // formData.append('dueDate', dueDate);
        if (file) {
            if (file instanceof File) {
                formData.append('file', file);
            }
        } else {
            formData.append('isImageDeleted', true);
        }
        formData.append('leadId', leadId);
        if (item.id) {
            updateLeadAttachment({ id: item.id, body: formData })
            toast.success("Updated" + "Successfully", {
                position: toast.POSITION.TOP_CENTER,
            });
        } else {
            addLeadAttachment(formData)
            toast.success("Added" + "Successfully", {
                position: toast.POSITION.TOP_CENTER,
            });
        }
    }

    function openPreview() {
        window.open(file instanceof File ? URL.createObjectURL(file) : getImageUrlPath(item.filePath))
    }

    return (
        <>
            {/* <Modal
                isOpen={previewFile}
                widthClass={"px-2 h-[80%] w-[80%]"}
                onClose={() => { setPreviewFile(false) }}
            >
                <iframe title='file' className='w-full h-full' src={file ? ((file instanceof File) ? URL.createObjectURL(file) : getImageUrlPath(file)) : ''} frameborder="0" />
            </Modal> */}
            <tr
                key={index}
                className="hover:bg-gray-100 transition duration-150"
            >
                <td className="py-0.5 px-3 border border-gray-400">
                    {index + 1}
                </td>
                <td className="py-0.5 px-3 border border-gray-400">
                    <input
                        type="date"
                        disabled={readOnly}
                        className="text-center rounded py-1 px-2 w-full border border-gray-300 focus:outline-none focus:ring focus:border-blue-300"
                        value={
                            moment(date).format("YYYY-MM-DD")
                        }
                        onChange={(e) =>
                            setDate(e.target.value)
                        }
                    />
                </td>
                <td className="py-0.5 px-3 border border-gray-400">
                    <input
                        type="text"
                        className="text-left rounded py-1 px-2 w-full border border-gray-300 focus:outline-none focus:ring focus:border-blue-300"
                        value={gridUser}
                        disabled={true}

                    />
                </td>
                <td className="py-0.5 px-3 border border-gray-400">
                    <input
                        type="text"
                        className="text-left rounded py-1 px-2 w-full border border-gray-300 focus:outline-none focus:ring focus:border-blue-300"
                        value={log}
                        disabled={readOnly}
                        onChange={(e) => {
                            setLog(e.target.value)
                        }}
                    />
                </td>
                <td className="py-0.5 px-3 border border-gray-400">
                    <div className='flex gap-2'>
                        {!readOnly &&
                            <input
                                type="file"
                                onChange={(e) => setFile(e.target.files[0])}
                            />
                        }
                        {file &&
                            <>
                                <button onClick={() => { openPreview() }}>
                                    {VIEW}
                                </button>
                                {!readOnly &&
                                    <button onClick={() => { setFile('') }}>
                                        {DELETE}
                                    </button>
                                }
                            </>
                        }
                    </div>

                </td>
                {!readOnly &&
                    <td className="py-0.5 px-3 border border-gray-400 text-center">
                        <div className='flex gap-2'>
                            <button
                                type="button"
                                onClick={() => deleteLeadAttachment(item.id)}
                                className="text-red-500 hover:text-red-700 transition duration-150"
                            >
                                {DELETE}
                            </button>
                            <SaveButton onClick={saveComments} isNameNeed={true} />
                        </div>
                    </td>
                }
            </tr>

        </>
    )
}

export default LeadAttachementForm
