import React, { useEffect, useState } from 'react';
import useOutsideClick from '../../../../src/CustomHooks/handleOutsideClick';
import { useDispatch } from "react-redux";
import { useGetPartyQuery, useAddPartyMutation } from '../../../redux/services/PartyMasterService';
import { Loader } from '../../../Basic/components';
import secureLocalStorage from 'react-secure-storage';
import { findFromList } from '../../../Utils/helper';

const VendorCreate = ({ setPartyId, partyId, name = null, readOnly, id, isVendor = null }) => {
  const [isListShow, setIsListShow] = useState(false);
  const inputRef = useOutsideClick(() => { setIsListShow(false); });
  const [filteredPages, setFilteredPages] = useState([]);
  const [search, setSearch] = useState("");

  const companyId = secureLocalStorage.getItem(
    sessionStorage.getItem("sessionId") + "userCompanyId"
  );
  const userId = secureLocalStorage.getItem(
    sessionStorage.getItem("sessionId") + "userId"
  );
  const { data: partyList, isLoading: isPartyLoading, isFetching: isPartyFetching } = useGetPartyQuery({ params: { companyId, userId } });

  const [addData] = useAddPartyMutation();

  useEffect(() => {
    let pageSearchComponent = document.getElementById("pageSearch");
    if (!pageSearchComponent) return;
    pageSearchComponent.addEventListener('keydown', function (ev) {
      var focusableElementsString = '[tabindex="0"]';
      let ol = document.querySelectorAll(focusableElementsString);
      if (ev.key === "ArrowDown") {
        for (let i = 0; i < ol.length; i++) {
          if (ol[i] === ev.target) {
            let o = i < ol.length - 1 ? ol[i + 1] : ol[0];
            o.focus(); break;
          }
        }
        ev.preventDefault();
      } else if (ev.key === "ArrowUp") {
        for (let i = 0; i < ol.length; i++) {
          if (ol[i] === ev.target) {
            let o = ol[i - 1];
            o.focus(); break;
          }
        }
        ev.preventDefault();
      }
    });
    return () => {
      pageSearchComponent.removeEventListener('keydown', () => { });
    };
  }, []);

  useEffect(() => {
    if (!partyList) return;
    if (!search) { setFilteredPages(partyList.data); }
    if (isVendor) {
      setFilteredPages(partyList?.data?.filter(item => item?.isSupplier)?.filter(page => page.name.toLowerCase().includes(search.toLowerCase())));
    } else {
      setFilteredPages(partyList.data.filter(page => page.name.toLowerCase().includes(search.toLowerCase())));
    }
  }, [search, partyList, isPartyFetching, isPartyLoading]);

  const handleAddNewParty = async () => {
    let response = await addData({ name: search, companyId, userId, isSupplier: true }).unwrap();
    setPartyId(response.data.id);
  };

  if (!partyList) return <Loader />;

  return (
    <div id='pageSearch' className='relative flex flex-col text-black z-10 w-full' ref={inputRef}>
      <div className='flex flex-col md:px-1'>
        <label className={`text-sm font-medium text-black mb-1`}>{name} <span className='text-red-600'>*</span></label>

        {id ? (
          <div className={`text-left  border border-gray-300 rounded-md bg-white`}>
            {findFromList(partyId, partyList ? partyList.data : [], "name")}
          </div>
        ) : (
          <div className='relative'>
            {isListShow ? (
              <input
                type="text"
                readOnly={readOnly}
                className={`w-full md:w-full p-1.5 border border-black rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-600 text-xs`}
                value={search}
                onChange={(e) => { setSearch(e.target.value); }}
                onFocus={() => { setIsListShow(true); }}
                tabIndex={0}
              />
            ) : (
              <input
                type="text"
                readOnly={readOnly}
                className={`w-full md:w-full p-1.5 border border-black rounded-md bg-white focus:outline-none focus:ring-2 focus:ring-indigo-600 text-xs`}
                value={findFromList(partyId, partyList ? partyList.data : [], "name")}
                tabIndex={0}
                onFocus={() => { setIsListShow(true); }}
              />
            )}
            {isListShow && (
              <ul className='absolute mt-1 max-h-60 overflow-auto bg-white shadow-lg rounded-md z-20 w-full'>
                {search && (
                  <li
                    tabIndex={0}
                    className='p-1 cursor-pointer hover:bg-white text-xs'
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        handleAddNewParty();
                        setSearch("");
                        setIsListShow(false);
                      }
                    }}
                    onClick={() => {
                      handleAddNewParty();
                      setSearch("");
                      setIsListShow(false);
                    }}
                  >Create Party {`"${search}"`}
                  </li>
                )}
                {filteredPages.map((party) => (
                  <li
                    className='p-1 cursor-pointer hover:bg-gray-100 text-xs'
                    key={party.id}
                    tabIndex={0}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        setPartyId(party.id);
                        setSearch("");
                        setIsListShow(false);
                      }
                    }}
                    onClick={() => {
                      setPartyId(party.id);
                      setSearch("");
                      setIsListShow(false);
                    }}
                  >
                    <div className='flex justify-between'>
                      <span>{party.name}</span>
                      <span className='text-gray-500 text-xs'>{party?.address || ""}</span>
                    </div>
                  </li>
                ))}
              </ul>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default VendorCreate;
