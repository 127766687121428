import { useDispatch } from "react-redux";

const useInvalidateTags = () => {
  const dispatch = useDispatch();

  const apiInvalidateData = [
    {
      type: `Invoice/invalidateTags`,
      payload: ["Invoice"],
    },
    {
      type: `leadForm/invalidateTags`,
      payload: ["Lead"],
    },
    {
      type: `Project/invalidateTags`,
      payload: ["Project"],
    },
    {
      type: `quotes/invalidateTags`,
      payload: ["Quotes"],
    },
    {
      type: `projectPayment/invalidateTags`,
      payload: ["ProjectPayment"],
    },
  ];

  function dispatchInvalidate() {
    apiInvalidateData.forEach(item => {
        dispatch(item);
    })
  }
  return [dispatchInvalidate];
};

export default useInvalidateTags;
