import React from 'react';
import { Document, Page, Text, View, Image } from '@react-pdf/renderer';
import tw from '../../../Utils/tailwind-react-pdf';
import { findFromList, getDateFromDateTimeToDisplay, substract } from '../../../Utils/helper';
import { COMPANY_NAME } from '../../../Constants';
import { DOCID } from '../../../Constants';
import Page2 from './Page2';
import Page3 from './page3';
import moment from 'moment';
import { useGetStateQuery } from '../../../redux/services/StateMasterService';
import secureLocalStorage from 'react-secure-storage';
import { Loader } from '../../../Basic/components';
import { toWords } from 'number-to-words';


const PrintFormat = ({ data, isDrawingWeight, isIgst, stateList, quoteVersion, party }) => {

    const items = data?.DeliveryNoteGroupedDetails || [];
    const totalWeight = items.reduce((a, groupItem) => a + (
        (parseFloat(isDrawingWeight ? (groupItem?.items.reduce((a, c) => a + parseFloat(c.weight), 0)) : (groupItem?.physical_weight)) * parseFloat(groupItem?.items.reduce((a, c) => a + parseFloat(c.qty), 0)))
    ), 0);
    const totalQty = items.reduce((a, c) => a + parseFloat(c?.items.reduce((a, c) => a + parseFloat(c.qty), 0)), 0);

    const calculateGst = (index) => {
        let quoteData = data?.QuotesItems
        return quoteData[index]["taxPercent"]?.replace("%", "")

    }
    const calGst = (id) => {
        let taxPercent = data?.QuotesItems?.find(val => parseInt(val.id) === parseInt(id))?.taxPercent

        return taxPercent.replace("%", "")

    }


    function findTaxableAmount() {
        return data.QuotesItems?.reduce((a, b) => a + (parseInt(b.qty) * parseInt(b.price)), 0)

    }

    function findIgstAmount() {
        return data.QuotesItems?.reduce((a, b) => a + ((parseFloat(b.qty) * parseFloat(b.price)) * (calGst(b.id) / 100)), 0)

    }
    const amount = parseFloat(findTotalAmount()).toFixed(2);
    const amountInWords = toWords(amount).toUpperCase();

    function findCgstAmount() {
        return data.QuotesItems?.reduce((a, b) => a + ((parseFloat(b.qty) * parseFloat(b.price)) * ((calGst(b.id) / 2) / 100)), 0)

    }

    function findTotalAmount() {
        return data.QuotesItems?.filter(item => item.quoteVersion == quoteVersion)?.reduce((a, b) => a + (substract(parseFloat(b.qty) * parseFloat(b.price), parseFloat(b?.discount || 0)) + ((parseFloat(b.qty) * parseFloat(b.price)) * (calGst(b.id) / 100))), 0)

    }




    return (
        <Document style={tw("w-full h-full")}>
            <Page size="A4" style={{ fontFamily: "Times-Roman", ...tw("relative pb-[50px] px-8") }}>
                <View fixed style={tw("w-full pt-1")}>
                    <View style={tw(" flex flex-row justify-between items-center p-1")}>
                        <View style={tw("w-2/8")}>
                            <Image source={require("../../../assets/uniass.jpeg")} style={{ width: 55, height: 55 }} />
                        </View>
                        <View style={tw("w-1/2 rounded-md text-center pl-10")}>
                            <Text style={{ fontSize: 16 }}>{COMPANY_NAME}</Text>
                        </View>
                        <View style={tw("w-1/4 pl-10")}>
                            <Text style={{ fontSize: 16 }}>{DOCID}</Text>
                        </View>
                    </View>
                </View>
                <View fixed style={tw("w-full  ")}>
                    <View style={tw("flex flex-row justify-between p-1 border border-gray-500")}>
                        <View style={tw("w-2/4 gap-y-0.5")}>
                            <Text style={[tw("text-sky-800"), { fontSize: 16, fontWeight: "bold", }]}>Universal Associates</Text>

                            <Text style={{ fontSize: 12, marginBottom: 5 }}>
                                148 Nataraj Layout
                                15 Velamapalayam,
                                Tiruppur,{'\n'} TN (33) 641652
                            </Text>
                            <Text style={{ fontSize: 12, marginBottom: 5 }}>+919597639777</Text>
                            <Text style={{ fontSize: 12, marginBottom: 5 }}>1universalassociates@gmail.com</Text>
                            <View style={tw("flex flex-row gap-x-2 ")}>
                                <Text style={[tw("font-bold"), { fontSize: 10, fontWeight: 900, fontFamily: "Times-Bold" }]}>GSTIN :</Text>
                                <Text style={{ fontSize: 10, marginBottom: 3 }}> 33BIIPS8122C1ZF</Text>

                            </View>
                            <View style={tw("flex flex-row gap-x-2 ")}>
                                <Text style={[tw("font-bold"), { fontSize: 10, fontWeight: 900, fontFamily: "Times-Bold" }]}>Website:</Text>
                                <Text style={{ fontSize: 10, marginBottom: 3 }}> universalassociates.co.in</Text>

                            </View>
                            <View style={tw("flex flex-row gap-x-2 ")}>
                                <Text style={[tw("font-bold"), { fontSize: 10, fontWeight: 900, fontFamily: "Times-Bold" }]}>Contact Name:</Text>
                                <Text style={{ fontSize: 10, marginBottom: 3 }}> Universal Associates</Text>

                            </View>

                        </View>
                        <View style={tw("flex flex-row justify-end w-1/2 mt-1")}>
                            <View style={tw("w-1/2 gap-y-2")}>
                                <Text style={{ fontSize: 11 }}>Issue Date:</Text>
                                <Text style={{ fontSize: 11 }}>Valid Until:</Text>
                                <Text style={{ fontSize: 11 }}>Place of Supply:</Text>
                            </View>
                            <View style={tw("w-1/3  gap-y-2")}>
                                <Text style={{ fontSize: 11 }}>{moment(data?.createdAt).format("YYYY-MM-DD")}</Text>
                                <Text style={{ fontSize: 11 }}>{moment(data?.validDate).format("YYYY-MM-DD")}</Text>
                                <Text style={{ fontSize: 9 }}>{findFromList(data?.placeOfSupplyId, stateList?.data, "name")}</Text>
                            </View>
                        </View>
                    </View>
                </View>
                <View fixed style={tw("w-full")}>
                    <View style={tw("flex flex-row justify-between p-1")}>
                        <View style={tw("w-2/4")}>
                            <Text style={[tw("bg-sky-800  text-white p-1 mr-1"), { fontSize: 13 }]}>
                                Bill To
                            </Text>

                            <Text style={[tw("text-sky-800"), { fontSize: 13 }]}>
                                {party.contactPersonName}
                            </Text>
                            <Text style={{ fontSize: 10, marginBottom: 5 }}>
                                {party.address}{" "}
                            </Text>
                            <Text style={{ fontSize: 10, marginBottom: 5 }}>
                                PINCODE: {party.pincode}{" "}
                            </Text>

                            <Text style={{ fontSize: 10, marginBottom: 5 }}>
                                CONTACT: {party.contactMobile}{" "}
                            </Text>
                            <Text style={{ fontSize: 10, marginBottom: 5 }}>
                                GSTIN: {party.gstNo}{" "}
                            </Text>
                        </View>

                        <View style={tw("w-2/4  pr-3")}>
                            <Text style={[tw("bg-sky-800  text-white p-1"), { fontSize: 13 }]}>
                                Ship To
                            </Text>
                            {party.ShippingAddress.map((item, index) => (
                                <Text key={index} style={{ fontSize: 10 }}>
                                    {item.address}
                                </Text>
                            ))}
                        </View>
                    </View>
                </View>
                <View style={tw("w-full text-sm bg-sky-800 text-white  border border-gray-500 text-center flex flex-row h-[30px]")}>
                    <View style={tw("flex flex-row border-r border-gray-500 justify-center items-center w-[5%]")}>
                        <Text>S.No</Text>
                    </View>
                    <View style={tw(" border-r border-gray-500 justify-center items-center w-[30%]")}>
                        <Text>Item Description</Text>
                    </View>
                    <View style={tw("flex flex-row border-r border-gray-500 justify-center items-center w-[5%]")}>
                        <Text>HSN</Text>
                    </View>
                    <View style={tw("flex flex-row border-r border-gray-500 justify-center items-center w-[5%]")}>
                        <Text>Qty </Text>
                    </View>
                    <View style={tw("flex flex-row border-r border-gray-500 justify-center items-center w-[5%]")}>
                        <Text> UoM</Text>
                    </View>
                    <View style={tw("flex flex-row border-r border-gray-500 justify-center items-center w-[8%]")}>
                        <Text>Price <span>&#8377;</span></Text>
                    </View>
                    <View style={tw("flex flex-row border-r border-gray-500 justify-center items-center w-[13%]")}>
                        <Text>Tax.Value <span>&#8377;</span></Text>
                    </View>
                    {
                        isIgst ?
                            <View style={tw("flex flex-row border-r border-gray-500 justify-center items-center w-[12%]")}>
                                <Text>CGST <span>&#8377;</span></Text>
                            </View>
                            :
                            <>
                                <View style={tw("flex flex-row border-r border-gray-500 justify-center items-center w-[11%]")}>
                                    <Text>CGST <span>&#8377;</span></Text>
                                </View>
                                <View style={tw("flex flex-row border-r border-gray-500 justify-center items-center w-[11%]")}>
                                    <Text>SGST <span>&#8377;</span></Text>
                                </View>
                            </>

                    }

                    <View style={tw("flex flex-row border-r border-gray-500 justify-center items-center w-[12%]")}>
                        <Text>Amount <span>&#8377;</span></Text>
                    </View>
                </View>
                {data?.QuotesItems.map((item, index) =>
                    <View key={index} style={tw("w-full text-xs border-x border-b border-gray-500 text-center flex flex-row ")}>
                        <View style={tw("flex flex-row border-r border-gray-500 justify-center items-center w-[5%]")}>
                            <Text>
                                {index + 1}
                            </Text>
                        </View>
                        <View style={tw("py-1 border-r border-gray-500  w-[30%] gap-y-2 overflow-auto")}>
                            <Text>
                                {item?.Product?.name}

                            </Text>
                            <Text>
                                {item?.Product?.description}
                            </Text>
                        </View>
                        <View style={tw(" border-r border-gray-500 justify-around text-right px-1 w-[5%]")}>
                            <Text>
                                {item?.Product?.hsnCode}

                            </Text>
                        </View>
                        <View style={tw("border-r border-gray-500 border-r justify-around text-right px-1  w-[5%]")}>
                            <Text>
                                {item?.qty}
                            </Text>
                        </View>
                        <View style={tw(" border-r border-gray-500 justify-center text-left px-1 w-[5%]")}>
                            <Text>
                                {item?.Uom?.name}
                            </Text>
                        </View>
                        <View style={tw(" border-r border-gray-500 justify-center text-right px-1 w-[8%]")}>
                            <Text>
                                {parseFloat(item?.price).toFixed(2)}
                            </Text>
                        </View>
                        <View style={tw(" border-r border-gray-500 justify-center  text-right px-1 w-[13%]")}>
                            <Text>
                                {(!item.qty || !item.price) ? 0 : parseFloat((parseFloat(item.qty) * parseFloat(item.price))).toFixed(2) || 0}
                            </Text>
                        </View>

                        {
                            isIgst ?
                                <View style={tw(" border-r border-gray-500 justify-center text-right px-1 w-[12%]")}>

                                    <Text>
                                        {(!item.qty || !item.price) ? 0 : parseFloat(((parseFloat(item.qty) * parseFloat(item.price)) * (calculateGst(index) / 100))).toFixed(2) || 0}
                                    </Text>
                                </View>
                                :
                                <>
                                    <View style={tw(" border-r border-gray-500 justify-center text-right px-1 w-[11%]")}>

                                        <Text>
                                            {(!item.qty || !item.price) ? 0 : parseFloat(((parseFloat(item.qty) * parseFloat(item.price)) * ((calculateGst(index) / 2) / 100))).toFixed(2) || 0}

                                        </Text>
                                    </View>
                                    <View style={tw(" border-r border-gray-500 justify-center text-right px-1 w-[11%]")}>

                                        <Text>
                                            {(!item.qty || !item.price) ? 0 : parseFloat(((parseFloat(item.qty) * parseFloat(item.price)) * ((calculateGst(index) / 2) / 100))).toFixed(2) || 0}

                                        </Text>
                                    </View>
                                </>


                        }


                        <View style={tw(" border-r border-gray-500 justify-center text-right px-1 w-[12%]")}>
                            <Text>
                                {(!item.qty || !item.price) ? 0 : parseFloat((substract(parseFloat(item.qty) * parseFloat(item.price), parseFloat(item?.discount || 0)) + ((parseFloat(item.qty) * parseFloat(item.price)) * (calculateGst(index) / 100)))).toFixed(2) || 0}
                            </Text>
                        </View>
                    </View>
                )}
                <View style={tw("w-full text-sm border-x text-sky-800 font-bold  border-b border-gray-500 text-center flex flex-row h-[20px]")}>
                    <View style={tw("flex flex-row border-r border-gray-500 justify-center items-center w-[58%]")}>
                        <Text>
                            Total @ 0%
                        </Text>
                    </View>
                    <View style={tw("flex flex-row border-r border-gray-500 justify-end items-center w-[13%]")}>
                        <Text>
                            {parseFloat(findTaxableAmount()).toFixed(2)}
                        </Text>
                    </View>

                    {
                        isIgst ?

                            <View style={tw("flex flex-row border-r border-gray-500 justify-end items-center w-[12%]")}>
                                <Text>
                                    {parseFloat(findIgstAmount()).toFixed(2)}
                                </Text>
                            </View>
                            :
                            <>
                                <View style={tw("flex flex-row border-r border-gray-500 justify-end items-center w-[11%]")}>
                                    <Text>
                                        {parseFloat(findCgstAmount()).toFixed(2)}
                                    </Text>
                                </View>
                                <View style={tw("flex flex-row border-r border-gray-500 justify-end items-center w-[11%]")}>
                                    <Text>
                                        {parseFloat(findCgstAmount()).toFixed(2)}
                                    </Text>
                                </View>
                            </>

                    }


                    <View style={tw("flex flex-row border-r border-gray-500 justify-end items-center w-[12%]")}>
                        <Text>
                            {parseFloat(findTotalAmount()).toFixed(2)}
                        </Text>
                    </View>

                </View>






                <View fixed style={tw("absolute bottom-5")}>
                    <View style={tw("text-center w-full pb-1 pt-1 px-2 text-xs ")}>
                        <Text render={({ pageNumber, totalPages }) => (
                            `Page ${pageNumber} / ${totalPages}`
                        )} fixed />
                    </View>
                </View>
                <View fixed style={tw("w-full")}>
                    <View style={tw("flex flex-row justify-between pb-20 pt-5")}>
                        <View style={tw("w-1/2 pr-3")}>
                            <View style={tw("flex flex-row justify-between")}> <Text style={{ fontSize: 12, marginBottom: 5 }}>
                                Bank Name:
                            </Text>
                                <Text style={tw("text-sky-800 text-[12px] ")}>Karur Vysya Bank</Text></View>
                            <View style={tw("flex flex-row justify-between")}> <Text style={{ fontSize: 12, marginBottom: 5 }}>
                                Account Number:
                            </Text>
                                <Text style={tw("text-sky-800 text-[12px] ")}>1779135000004779</Text></View>
                            <View style={tw("flex flex-row justify-between")}>
                                <Text style={{ fontSize: 12, marginBottom: 5 }}>
                                    Branch Name:
                                </Text>
                                <Text style={tw("text-sky-800 text-[12px]  ")}>Velampalayam</Text>
                            </View>
                            <View style={tw("flex flex-row justify-between")}>
                                <Text style={{ fontSize: 12, marginBottom: 5 }}>
                                    IFSC Code:
                                </Text>
                                <Text style={tw("text-sky-800 text-[12px] ")}>KVBL0001779</Text>
                            </View>
                        </View>

                        <View style={tw("w-1/2 pr-3")}>
                            <Text style={{ fontSize: 11 }}>Total Taxable Value</Text>
                            <Text style={{ fontSize: 11 }}>Total Tax Amount</Text>
                            <Text style={{ fontSize: 11 }}>Total Value (in figure)</Text>
                            <Text style={{ fontSize: 11 }}>Total Value (in words)</Text>

                        </View>
                        <View style={tw("w-1/3")}>
                            <Text style={[tw("text-sky-800"), { fontSize: 11 }]}><span>&#8377;</span>     {parseFloat(findTaxableAmount()).toFixed(2)}</Text>
                            <Text style={[tw("text-sky-800"), { fontSize: 11 }]}><span>&#8377;</span> {parseFloat(findIgstAmount()).toFixed(2)}</Text>
                            <Text style={[tw("text-sky-800"), { fontSize: 11 }]}><span>&#8377;</span> {parseFloat(findTotalAmount()).toFixed(2)}</Text>
                            <Text style={{ fontSize: 8, textAlign: 'left' }}>
                                {amountInWords}
                            </Text>
                        </View>
                    </View>
                </View>
                <View fixed style={tw("w-full bg-sky-800 align-center text-white p-1")}>
                    <Text style={{ fontSize: 8, textAlign: 'left' }}>
                        AMOUNT IN WORDS: {amountInWords}
                    </Text>
                </View>
            </Page>
            <Page2 />
            {/* <Page3 /> */}
        </Document>
    );
};

export default PrintFormat;
