import React, { useCallback, useEffect, useRef, useState } from "react";
import { getDateFromDateTime, substract } from "../../../Utils/helper";
import { DisabledInput, TextArea, TextInput, CheckBox } from "../../../Inputs";
import { useGetPartyByIdQuery } from "../../../redux/services/PartyMasterService";
import { paymentMethods } from "../../../Utils/DropdownData";
import FormReport from "../../../Basic/components/FormReportTemplate";
import { useDispatch } from "react-redux";
import secureLocalStorage from "react-secure-storage";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faEdit, faSave, faTimes } from '@fortawesome/free-solid-svg-icons';
import PrintFormat from "./PrintFormat";
import tw from "../../../Utils/tailwind-react-pdf";
import { PDFViewer } from '@react-pdf/renderer';
import {
  useAddProjectPaymentMutation,
  useDeleteProjectPaymentMutation,
  useGetProjectPaymentByIdQuery,
  useGetProjectPaymentQuery,
  useUpdateProjectPaymentMutation,
} from "../../../redux/services/ProjectPaymentService";
import FormHeader from "../../../Basic/components/FormHeader";
import moment from "moment";
import { useGetQuotesQuery } from "../../../redux/services/QuotesService";
import { useSelector } from "react-redux";
import { useGetProjectByIdQuery } from "../../../redux/services/ProjectService";
import Report from "./Report";
import Modal from "../../../UiComponents/Modal";
import useInvalidateTags from "../../../CustomHooks/useInvalidateTags";
import { push } from "../../../redux/features/opentabs";

export default function Form({ }) {

  const MODEL = "Project Payments";
  const openTabs = useSelector((state) => state.openTabs);


  const paymentUpdate = openTabs.tabs.find(
    (i) => i.name == "PROJECT PAYMENTS"
  )?.projectId;

  const projectForm = openTabs.tabs.find(
    (i) => i.name == "PROJECT PAYMENTS"
  )?.projectForm;

  useEffect(() => {
    if (!paymentUpdate) return;
    // if (paymentUpdate == "new") {
    //   onNew();
    //   setForm(projectForm);
    // } else {
    setProjectId(paymentUpdate);
    setForm(projectForm);
    setId("");
    // }
    dispatch(push({ name: "PROJECT PAYMENTS", projectForm: null }));
  }, [paymentUpdate, projectForm]);
  const today = new Date();

  const [userDate, setUserDate] = useState();
  const [isPaymentCompleted, setIsPaymentCompleted] = useState(false)
  const [readOnly, setReadOnly] = useState(false);
  const [id, setId] = useState("");
  const [docId, setDocId] = useState("");
  const [amount, setAmount] = useState("0");
  const [date, setDate] = useState(getDateFromDateTime(today));
  const [bankCharges, setBankCharges] = useState();
  const [print, setPrint] = useState(false);
  const [finalDiscountAmount, setFinalDiscountAmount] = useState("")
  const [notes, setNotes] = useState();
  const [paymentMethod, setPaymentMethod] = useState("");
  const [projectAmount, setProjectAmount] = useState();
  const [alreadyPayment, setAlreadyPayment] = useState();
  const [projectId, setProjectId] = useState();
  const [balanceAmount, setBalanceAmount] = useState();
  const [isCompany, setIsCompany] = useState(false);
  const [isPersonal, setIsPersonal] = useState(false);
  const [handoverTo, setHandoverTo] = useState("");
  const [collectBy, setCollectBy] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const childRecord = useRef(0);
  const dispatch = useDispatch();
  const [formReport, setFormReport] = useState(false);

  const [form, setForm] = useState(
    openTabs.tabs.find((i) => i.name == "PROJECT PAYMENTS")?.projectForm ||
    false
  );

  const companyId = secureLocalStorage.getItem(
    sessionStorage.getItem("sessionId") + "userCompanyId"
  );
  const branchId = secureLocalStorage.getItem(
    sessionStorage.getItem("sessionId") + "currentBranchId"
  );
  const userId = secureLocalStorage.getItem(
    sessionStorage.getItem("sessionId") + "userId"
  );
  const params = {
    companyId,
    branchId,
    projectId: parseInt(projectId),
  };
  const {
    data: singleProjectData,
    isFetching: isSingleProjectFetching,
    isLoading: isSingleProjectLoading,
  } = useGetProjectByIdQuery(projectId, { skip: !projectId });

  const { data: clientData } = useGetPartyByIdQuery(
    singleProjectData?.data?.clientId,
    { skip: !singleProjectData?.data?.clientId }
  );
  const {
    data: allData,
    isLoading,
    isFetching,
  } = useGetProjectPaymentQuery({ params, searchParams: searchValue });


  const {
    data: singleData,
    isFetching: isSingleFetching,
    isLoading: isSingleLoading,
  } = useGetProjectPaymentByIdQuery({ id }, { skip: !id });

  const [addData] = useAddProjectPaymentMutation();
  const [updateData] = useUpdateProjectPaymentMutation();
  const [removeData] = useDeleteProjectPaymentMutation();

  const { data: quotesData } = useGetQuotesQuery({ params });

  function getNextDocId() {
    if (id || isLoading || isFetching) return;
    if (allData?.nextDocId) {
      setDocId(allData.nextDocId);
    }
  }
  const filteredPayment = allData?.data
    ? allData.data.filter(item => item.projectId === (singleData?.data ? singleData?.data?.projectId : ''))
    : [];


  // const getDocId = useCallback(() => {
  //     if (id || isLoading || isFetching) return
  //     if (allData?.nextDocId) {
  //         setDocId(allData.nextDocId)
  //     }
  // }, [allData, isLoading, isFetching, id])

  // useEffect(getDocId, [getDocId])

  const syncFormWithDb = useCallback(
    (data) => {
      // if (allData?.nextDocId) {
      //     setDocId(allData.nextDocId)
      // }
      if (id) {
        setReadOnly(true);
      } else {
        setReadOnly(false);
      }
      if (data?.docId) {
        setDocId(data?.docId);
      }
      setDate(
        data?.createdAt
          ? moment.utc(data.createdAt).format("YYYY-MM-DD")
          : moment.utc(today).format("YYYY-MM-DD")
      );
      // setProjectAmount(data?.projectAmount ? data?.projectAmount : "");
      setAmount(data?.amount ? data?.amount : "0");
      setFinalDiscountAmount(data?.finalDiscountAmount ? data?.finalDiscountAmount : "");
      setPaymentMethod(data?.paymentMethod ? data?.paymentMethod : "CASH");
      setIsCompany(data?.isCompany ? data?.isCompany : false);
      setIsPersonal(data?.isPersonal ? data?.isPersonal : false);
      setHandoverTo(data?.handoverTo ? data?.handoverTo : "");
      setCollectBy(data?.collectBy ? data?.collectBy : "");
      setNotes(data?.notes ? data?.notes : "");
      setAlreadyPayment(data?.alreadyPayment ? data?.alreadyPayment : "");
      setUserDate(
        data?.userDate ? moment(data?.userDate).format("YYYY-MM-DD") : ""
      );
      setIsPaymentCompleted(data?.isPaymentCompleted ? data?.isPaymentCompleted : false)
      childRecord.current = data?.childRecord ? data?.childRecord : 0;
    },
    [id]
  );

  const [invalidateTagsDispatch] = useInvalidateTags();


  useEffect(() => {
    syncFormWithDb(singleData?.data);
  }, [isSingleFetching, isSingleLoading, id, syncFormWithDb, singleData]);

  const data = {
    companyId: secureLocalStorage.getItem(
      sessionStorage.getItem("sessionId") + "userCompanyId"
    ),
    id,
    projectId,
    userDate,
    projectAmount,
    amount,
    paymentMethod,
    isCompany,
    isPersonal,
    collectBy,
    handoverTo,
    notes,
    branchId,
    partyId: singleProjectData?.data?.clientId || "",
    alreadyPayment,
    isPaymentCompleted,
    finalDiscountAmount,
  };

  const validateData = (data) => {
    if (data.projectAmount && data.amount && data.paymentMethod) {
      return true;
    }
    return false;
  };

  const handleSubmitCustom = async (callback, data, text) => {
    try {
      let returnData = await callback(data).unwrap();

      if (returnData.statusCode === 0) {
        setId(returnData?.data?.id);
        // syncFormWithDb(undefined)
        toast.success(text + "Successfully");
      } else {
        toast.error(returnData?.message);
      }
      invalidateTagsDispatch()
    } catch (error) {
      console.log("handle");
    }
  };

  const saveData = () => {
    if (!validateData(data)) {
      toast.info("Please fill all required fields...!", {
        position: "top-center",
      });
      return;
    }
    if (!window.confirm("Are you sure save the details ...?")) {
      return;
    }

    if (id) {
      handleSubmitCustom(updateData, data, "Updated");
    } else {
      handleSubmitCustom(addData, data, "Added");
    }
  };

  const deleteData = async () => {
    if (id) {
      if (!window.confirm("Are you sure to delete...?")) {
        return;
      }
      try {
        let returnData = await removeData(id).unwrap();
        if (returnData.statusCode === 0) {
          setId("");
          syncFormWithDb(undefined);
          toast.success("Deleted Successfully");
        } else {
          toast.error(returnData?.message);
        }
        invalidateTagsDispatch()

      } catch (error) {
        toast.error("something went wrong");
      }
    }
  };

  const handleKeyDown = (event) => {
    let charCode = String.fromCharCode(event.which).toLowerCase();
    if ((event.ctrlKey || event.metaKey) && charCode === "s") {
      event.preventDefault();
      saveData();
    }
  };

  const onNew = () => {
    if (allData?.nextDocId) {
      setDocId(allData.nextDocId);
    }
    setId("");
    setReadOnly(false);
    setSearchValue("");
    setNotes("");
    syncFormWithDb(undefined);

    const sumAmount = allData?.data
      ? allData?.data?.reduce((a, b) => a + parseFloat(b.amount), 0)
      : 0;
    setAlreadyPayment(parseFloat(sumAmount).toFixed(3));
  };

  function onDataClick(id) {
    setId("");
    setProjectId(id);
    setForm(true);
  }

  const tableHeaders = ["Payment", "Pymt.Date", "Amount"];
  const tableDataNames = [
    "dataObj?.paymentMethod",
    "dataObj?.userDate",
    "dataObj.amount",
  ];

  useEffect(() => {
    if (!quotesData?.data) return
    if (!projectId) return
    let quotesItems = quotesData?.data?.find(
      (val) => parseInt(val.projectId) === parseInt(projectId)
    );
    let currentVersion = quotesItems?.quoteVersion;
    let totalCost;
    let transportTaxValue;

    totalCost = quotesItems?.QuotesItems?.filter((item) => item.quoteVersion == currentVersion) || [];

    totalCost = totalCost?.reduce((a, b, index) => a + (substract(parseFloat(b.qty) * parseFloat(b.price), parseFloat(b?.discount || 0)) + parseFloat(b.qty) * parseFloat(b.price) * ((b.taxPercent ? b.taxPercent.replace("%", "") : 0) / 100)), 0);

    if (quotesItems?.transportTax?.includes("%")) {
      transportTaxValue =
        quotesItems?.transportCost *
        (quotesItems?.transportTax.replace("%", "") / 100);
    } else {
      transportTaxValue =
        quotesItems?.transportCost * (quotesItems?.transportTax / 100);
    }



    totalCost = totalCost + parseInt(quotesItems?.transportCost || 0) + parseInt(transportTaxValue || 0);

    setProjectAmount(totalCost);
    setAmount("0")
  }, [
    projectId,
    quotesData,
    setDocId,
    singleProjectData?.data?.clientId,
    setProjectAmount,
  ]);

  useEffect(() => {
    if (id) return;
    if (allData?.nextDocId) {
      setDocId(allData.nextDocId || "");
    }
  }, [projectId, singleProjectData?.data?.clientId, setDocId, allData]);

  useEffect(() => {
    setBalanceAmount(
      parseFloat(projectAmount || 0) - (parseFloat(amount || 0) + parseFloat(alreadyPayment || 0) + parseFloat(finalDiscountAmount || 0)));
  }, [
    amount,
    finalDiscountAmount,
    projectAmount,
    id,
    setBalanceAmount,
    singleData,
    isSingleFetching,
    isSingleLoading,
    alreadyPayment,
  ]);

  useEffect(() => {
    if (id) return;
    let sumAmount = allData?.data
      ? allData?.data?.reduce((a, b, index) => a + parseFloat(b.amount), 0)
      : 0;
    setAlreadyPayment(sumAmount);
  }, [allData, isLoading, isFetching, setAlreadyPayment, id, projectAmount, projectId, singleProjectData?.data?.clientId]);

  const findTotalPayments = () => {
    let sumAmount = allData?.data
      ? allData?.data?.reduce((a, b, index) => a + parseFloat(b.amount), 0)
      : 0;
    return parseFloat(sumAmount).toFixed(2);
  };



  function checkPaymentFinished() {
    if (balanceAmount == 0) {
      return false
    }
    return true
  }

  if (!form)
    return <Report onNewButton={false} onClick={onDataClick} onNew={onNew} />;

  return (
    <div
      onKeyDown={handleKeyDown}
      className="md:items-start md:justify-items-center grid h-full bg-theme"
    >
      <Modal
        isOpen={formReport}
        onClose={() => setFormReport(false)}
        widthClass={"px-2 h-[100%] w-[80%]"}
      >
        <Report
          onClick={(id) => {
            setProjectId(id);
            setFormReport(false);
            setId("");
          }}
          onNewButton={false}
        />
      </Modal>
      <Modal isOpen={print} onClose={() => { setPrint(false) }} widthClass={"w-[90%] h-[90%]"} >
        <PDFViewer style={tw("w-full h-full")}>
          <PrintFormat data={singleData?.data} clientName={clientData?.data?.name} filteredPayment={filteredPayment} />
        </PDFViewer>
      </Modal>
      <div className="flex flex-col frame w-full h-full">
        <FormHeader
          onNew={onNew}
          onClose={() => {
            setForm(false);
            setSearchValue("");
          }}
          model={MODEL}
          openReport={() => setFormReport(true)}
          onPrint={id ? () => setPrint(true) : null}
        // saveData={saveData} setReadOnly={setReadOnly}
        // deleteData={deleteData}
        />

        <div className="flex-1 grid grid-cols-1 md:grid-cols-4 gap-x-2 overflow-clip">
          <div className="col-span-3 grid md:grid-cols-1 border overflow-auto">
            <div className="mr-1 md:ml-2">
              <fieldset className="frame my-1">
                <legend className="sub-heading">Payments</legend>

                <div className="grid grid-cols-6 gap-4 mt-2">
                  <DisabledInput
                    name="Doc Id."
                    value={docId}
                    required={true}
                    readOnly={true}
                    className="col-span-1"
                  />
                  <div className="col-span-1">
                    <DisabledInput
                      name="Quote Id"
                      value={singleProjectData?.data?.Quotes.map(item => item.docId) || ""}
                      disabled
                      required={true}
                      readOnly={true}
                      className="w-full"
                    />
                  </div>
                  <div className="col-span-1">
                    <DisabledInput
                      name="Lead Id"
                      value={singleProjectData?.data?.Quotes.map(item => item.LeadForm.docId) || ""}
                      disabled
                      required={true}
                      readOnly={true}
                      className="w-full"
                    />
                  </div>


                  <div className="col-span-1 flex flex-col">
                    <label className="text-sm font-medium mb-1">Payment Date</label>
                    <input
                      type="date"
                      className="focus:outline-none border border-gray-500 rounded w-full text-sm p-1"
                      id="id"
                      value={userDate}
                      onChange={(e) => setUserDate(e.target.value)}
                      readOnly={readOnly}
                    />
                  </div>

                  <div className="col-span-1 flex flex-col">
                    <label className="text-sm font-medium mb-1">Client Name</label>
                    <input
                      type="text"
                      className="focus:outline-none border border-gray-500 rounded w-full text-sm p-1"
                      value={clientData?.data?.name}
                      disabled
                    />
                  </div>


                  {readOnly ? <DisabledInput
                    name="Methods"
                    value={paymentMethod}
                    required={true}
                    readOnly={true}
                    className="col-span-1"
                  /> : <div className="col-span-1 flex flex-col">
                    <label className="text-sm font-medium mb-1">
                      Methods <span className="text-red-600">*</span>
                    </label>
                    <select
                      disabled={readOnly}
                      className="focus:outline-none border border-gray-500 rounded w-full text-sm p-1"
                      value={paymentMethod}
                      onChange={(e) => setPaymentMethod(e.target.value)}
                    >
                      <option className="text-gray-600" hidden></option>
                      {paymentMethods?.map((data) => (
                        <option value={data.value} key={data.show}>
                          {data.show}
                        </option>
                      ))}
                    </select>
                  </div>}


                  {paymentMethod !== "CASH" && paymentMethod !== "" && (
                    <>
                      <div className="col-span-1 mt-5">
                        <CheckBox
                          name="Company Ac"
                          value={isCompany}
                          setValue={setIsCompany}
                          readOnly={readOnly}
                        />
                      </div>
                      <div className="col-span-1 mt-5">
                        <CheckBox
                          name="Personal Ac"
                          value={isPersonal}
                          setValue={setIsPersonal}
                          readOnly={readOnly}
                        />
                      </div>
                    </>
                  )}


                  {paymentMethod === "CASH" && (
                    <>
                      <div className="col-span-1">
                        <TextInput
                          type="text"
                          required={true}
                          name="Collected By"
                          value={collectBy}
                          setValue={setCollectBy}
                          className="w-full"
                          inputClass="py-1"
                          readOnly={readOnly}
                        />
                      </div>
                      <div className="col-span-1">

                        <TextInput
                          type="text"
                          required={true}
                          name="Handover To"
                          value={handoverTo}
                          setValue={setHandoverTo}
                          className="w-full"
                          inputClass="py-1"
                          readOnly={readOnly}
                        />
                      </div>
                    </>

                  )}
                  <div className="col-span-1">
                    <label className="mb-1 text-start text-sm">Project.Amt</label>
                    <input
                      type="number"
                      className="w-full p-1 focus:outline-none border-gray-500  text-sm border rounded"
                      value={projectAmount ? parseFloat(projectAmount).toFixed(2) : 0}
                      // onChange={(e) => setProjectAmount(e.target.value)}
                      disabled
                    />
                  </div>

                  <div className="col-span-1">
                    <label className="mb-1 text-start  text-sm">
                      Amount <span className="text-red-600">*</span>
                    </label>
                    <input
                      type="number"
                      className="w-full  text-sm p-1 focus:outline-none border-gray-500 border rounded"
                      value={amount}
                      onChange={(e) => setAmount(e.target.value)}
                      readOnly={readOnly}
                    />
                  </div>

                  <div className="col-span-1">
                    <label className="mb-1 text-start  text-sm">Bal.Amount</label>
                    <input
                      type="number"
                      className="w-full  text-sm p-1 focus:outline-none border-gray-500 border rounded"
                      value={balanceAmount ? parseFloat(balanceAmount).toFixed(2) : 0}
                      disabled
                    />
                  </div>

                  <div className="col-span-1">
                    <label className="mb-1  text-sm text-start">Al.Received</label>
                    <input
                      type="number"
                      className="w-full p-1  text-sm focus:outline-none border-gray-500 border rounded"
                      value={alreadyPayment ? parseFloat(alreadyPayment).toFixed(3) : 0}
                      disabled
                    />
                  </div>
                  <div className="col-span-1">
                    <label className="mb-1 text-start text-sm">Discount.Amt</label>
                    <input
                      type="number"
                      className="w-full p-1 focus:outline-none border-gray-500  text-sm border rounded"
                      value={finalDiscountAmount}
                      onChange={(e) => setFinalDiscountAmount(e.target.value)}

                    />
                  </div>
                  <div className="col-span-4">
                    <label className="w-24 pl-1 text-sm">Notes :</label>
                    <textarea
                      className="focus:outline-none  w-full h-12 border border-gray-500 rounded"
                      value={notes}
                      onChange={(e) => setNotes(e.target.value)}
                      readOnly={readOnly}
                    >
                      {" "}
                    </textarea>
                  </div>
                  <div className="md:ml-[20%] pt-2">
                    <CheckBox
                      readOnly={checkPaymentFinished()}
                      name="IsPaymentCompleted"
                      value={isPaymentCompleted}
                      setValue={setIsPaymentCompleted}
                    // readOnly={readOnly}
                    />
                  </div>
                </div>




                <div className="flex gap-x-10 pb-5 pt-2 justify-center">
                  <button
                    className="bg-blue-600 text-white rounded-md p-1 w-32 h-10 mt-2 text-md flex items-center justify-center gap-2"
                    onClick={onNew}
                  >
                    <FontAwesomeIcon icon={faPlus} />
                    New
                  </button>
                  <button
                    className="bg-yellow-600 text-white rounded-md p-1 w-32 h-10 mt-2 text-md flex items-center justify-center gap-2"
                    onClick={() => setReadOnly(false)}
                  >
                    <FontAwesomeIcon icon={faEdit} />
                    Edit
                  </button>
                  <button
                    className="bg-green-600 text-white rounded-md p-1 w-32 h-10 mt-2 text-md flex items-center justify-center gap-2"
                    onClick={saveData}
                  >
                    <FontAwesomeIcon icon={faSave} />
                    Save
                  </button>
                  <button
                    className="bg-red-600 text-white rounded-md p-1 w-32 h-10 mt-2 text-md flex items-center justify-center gap-2"
                    onClick={() => setForm(false)}
                  >
                    <FontAwesomeIcon icon={faTimes} />
                    Close
                  </button>
                </div>
              </fieldset>
            </div>
          </div>

          <div className="frame hidden md:block overflow-x-hidden">
            <FormReport
              projectPayment={true}
              findTotalPayments={findTotalPayments}
              searchValue={searchValue}
              setSearchValue={setSearchValue}
              setId={setId}
              tableHeaders={tableHeaders}
              tableDataNames={tableDataNames}
              data={allData?.data}
              loading={isLoading || isFetching}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
