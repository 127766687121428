import React from 'react'
import { DisabledInput, TextArea, TextInput } from '../../../Inputs'

const Consolidation = ({ id, totalQty, totalDiscount, setTotalDiscount, transportCost, setTransportCost, findTotalAmount, readOnly, isTotalDiscount, setTransportTax, transportTax, setTermsAndCondition, termsAndCondition }) => {
    // const points = termsAndCondition?.split(".").map((point, index) => (
    //     <li key={index}>{point.trim()}</li>
    // ));


    return (
        <div className='fixed bottom-1  overflow-auto w-full text-xs px-10 bg-gray-100'>
            <fieldset className='frame rounded-tr-lg rounded-bl-lg rounded-br-lg w-full border border-gray-600 p-1 overflow-auto '>
                <legend className='sub-heading'>Consolidation</legend>
                <div className='grid gap-10 grid-cols-4 px-10'>


                    <TextInput name={"Total Discount"} value={totalDiscount} setValue={setTotalDiscount} readOnly={((readOnly) || (!isTotalDiscount)) ? true : false} />
                    <TextInput name={"Transport.Cost"} value={transportCost} setValue={setTransportCost} readOnly={readOnly} />
                    <TextInput name={"Transport.Tax"} value={transportTax} setValue={setTransportTax} readOnly={readOnly} />


                    <DisabledInput name={"Total Amount"} value={parseFloat(findTotalAmount()).toFixed(2)} type={"number"} textClassName='text-right px-2' />
                </div>
                <div className=' w-3/4 flex gap-x-9 justify-center items-center'>

                    <label className='text-center'>Terms And Condition :</label>
                    {/* {
                        id ?
                            <ul className=" w-3/4  overflow-auto focus:outline-none border border-gray-500 rounded p-2 text-xs" >
                                {points}
                            </ul>
                            : */}
                    <textarea className=" w-3/4  overflow-auto focus:outline-none border border-gray-500 rounded p-2 text-xs "
                        value={termsAndCondition}
                        rows={6}
                        disabled={readOnly}
                        onChange={(e) => setTermsAndCondition(e.target.value)}
                    >
                    </textarea>
                    {/* } */}


                </div>

            </fieldset>
        </div>
    )
}

export default Consolidation