import React from 'react'
import { DropdownInput, MultiSelectDropdown } from '../../Inputs';
import { dropDownListObject, multiSelectOption } from '../../Utils/contructObject';
import { findFromList, getCommonParams } from '../../Utils/helper';
import { useGetProjectQuery } from '../../redux/services/ProjectService';
import { useGetQuotesQuery } from '../../redux/services/QuotesService';

const QuoteDropdown = ({ id, projectDatas, multiSelect = true, withoutLabel = true, readOnly, name, selected, setSelected, clientId }) => {
    const params = getCommonParams();
    const { data: quoteList } = useGetQuotesQuery({ params: { ...params, clientId } });
    return (
        <>

            {
                id ?
                    (
                        <div className={`flex flex-col gap-y-1 mb-4`}>
                            <label className={`md:text-start flex text-sm `}>Quote</label>
                            <span className="bg-white  text-gray-600 p-1 border border-gray-500 md:col-span-2 col-span-1 rounded text-sm"   >
                                {findFromList(selected, quoteList ? quoteList.data : [], "docId")}
                            </span>
                        </div>
                    ) :
                    <>
                        {withoutLabel ?
                            <select value={selected} onChange={(e) => setSelected(e.target.value)} className=' table-data-input'>
                                <option value="">Select</option>
                                {(quoteList ? quoteList.data : []).map(item =>
                                    <option key={item.id} value={item.id}>{item.docId}</option>
                                )}
                            </select>
                            :
                            <>
                                {multiSelect ?
                                    <MultiSelectDropdown readOnly={readOnly} name={name} selected={selected} setSelected={setSelected}
                                        options={multiSelectOption(quoteList ? quoteList.data : [], "docId", "id")} />
                                    :
                                    <DropdownInput readOnly={readOnly} name={name} value={selected} setValue={setSelected}
                                        options={dropDownListObject(quoteList ? quoteList.data.filter(val => val.active).filter(val => parseInt(val.clientId) === parseInt(clientId))?.filter(j => !j.projectId) : [], "docId", "id")} />
                                }
                            </>
                        }
                    </>

            }


        </>
    )
}

export default QuoteDropdown
