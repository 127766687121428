import React, { useCallback, useEffect, useRef, useState } from "react";
import { findFromList, getDateFromDateTime } from "../../../Utils/helper";
import {
  DisabledInput,
  TextArea,
  TextInput,
  CheckBox,
  DateInput,
} from "../../../Inputs";
import {
  useGetPartyByIdQuery,
  useGetPartyQuery,
} from "../../../redux/services/PartyMasterService";
import { expenses, paymentMethods } from "../../../Utils/DropdownData";
import FormReport from "../../../Basic/components/FormReportTemplate";
import { useDispatch } from "react-redux";
import secureLocalStorage from "react-secure-storage";
import { toast } from "react-toastify";
import { FaPlus, FaEdit, FaSave, FaTimes } from "react-icons/fa";
import {
  useAddProjectPaymentMutation,
  useDeleteProjectPaymentMutation,
  useGetProjectPaymentByIdQuery,
  useGetProjectPaymentQuery,
  useUpdateProjectPaymentMutation,
} from "../../../redux/services/ProjectPaymentService";
import FormHeader from "../../../Basic/components/FormHeader";
import moment from "moment";
import PartySearchOnly from "./PartySearchOnly";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan, faUserPlus } from "@fortawesome/free-solid-svg-icons";
import LabourExpense from "./LabourExpense";
import VendorCreate from "./VendorCreate";
import { useGetUomQuery } from "../../../redux/services/UomMasterService";
import Report from "./Report";
import Modal from "../../../UiComponents/Modal";
import Modal1 from "./Model1";
import { useGetProjectByIdQuery } from "../../../redux/services/ProjectService";
import { useSelector } from "react-redux";
import { push } from "../../../redux/features/opentabs";
import useInvalidateTags from "../../../CustomHooks/useInvalidateTags";

export default function Form({ }) {
  const MODEL = "Project Expense";

  const openTabs = useSelector((state) => state.openTabs);
  const [projectId, setProjectId] = useState();


  const expenseUpdate = openTabs.tabs.find(i => i.name == "PROJECT EXPENSE")?.projectId;
  const projectForm = openTabs.tabs.find((i) => i.name == "PROJECT EXPENSE")?.projectForm;


  const [invalidateTagsDispatch] = useInvalidateTags();


  useEffect(() => {

    if (!expenseUpdate) return
    if (expenseUpdate == "new") {
      onNew();
    }
    else {
      setProjectId(expenseUpdate);
      setForm(projectForm)
    }
    dispatch(push({ name: "PROJECT EXPENSE", projectForm: null }))

  }, [expenseUpdate])


  const today = new Date();
  const [readOnly, setReadOnly] = useState(false);
  const [id, setId] = useState("");
  const [docId, setDocId] = useState("");
  const [amount, setAmount] = useState("");
  const [date, setDate] = useState(getDateFromDateTime(today));
  const [validDate, setValidDate] = useState();
  const [userDate, setUserDate] = useState();
  const [bankCharges, setBankCharges] = useState();
  const [notes, setNotes] = useState();
  const [paymentMethod, setPaymentMethod] = useState("");

  const [invoiceNo, setInvoiceNo] = useState();
  const [invoiceValue, setInvoiceValue] = useState();
  const [vendorId, setVendorId] = useState();
  const [balanceAmount, setBalanceAmount] = useState();
  const [isCompany, setIsCompany] = useState(false);
  const [isPersonal, setIsPersonal] = useState(false);

  const [handoverTo, setHandoverTo] = useState("");
  const [givenBy, setGivenBy] = useState();
  const [collectBy, setCollectBy] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [transportCost, setTransportCost] = useState();
  const [expenseItems, setExpenseItems] = useState([]);
  const [alreadyPayment, setAlreadyPayment] = useState();
  const [expenseType, setExpenseType] = useState();
  const [foodCost, setFoodCost] = useState();
  const [formExpenseType, setFormExpenseType] = useState("ALL");
  const [aliasName, setAliasName] = useState();
  const [contractAmount, setContractAmount] = useState();
  const [formReport, setFormReport] = useState(false);
  const [otherExpenses, setOtherExpenses] = useState();
  // const [form, setForm] = useState(false);
  const [form, setForm] = useState(openTabs.tabs.find(i => i.name == "PROJECT EXPENSE")?.projectForm || false);

  const childRecord = useRef(0);
  const dispatch = useDispatch();

  const companyId = secureLocalStorage.getItem(
    sessionStorage.getItem("sessionId") + "userCompanyId"
  );
  const branchId = secureLocalStorage.getItem(
    sessionStorage.getItem("sessionId") + "currentBranchId"
  );
  const userId = secureLocalStorage.getItem(
    sessionStorage.getItem("sessionId") + "userId"
  );
  const params = {
    companyId,
    branchId,
    projectId: parseInt(projectId),
    isExpenses: true,
  };
  const {
    data: singleProjectData,
    isFetching: isSingleProjectFetching,
    isLoading: isSingleProjectLoading,
  } = useGetProjectByIdQuery(projectId, { skip: !projectId });

  const { data: clientData } = useGetPartyByIdQuery(
    singleProjectData?.data?.clientId,
    { skip: !singleProjectData?.data?.clientId }
  );
  const {
    data: allData,
    isLoading,
    isFetching,
  } = useGetProjectPaymentQuery(
    { params, searchParams: searchValue },
    { skip: !projectId }
  );

  const {
    data: singleData,
    isFetching: isSingleFetching,
    isLoading: isSingleLoading,
  } = useGetProjectPaymentByIdQuery({ id, params }, { skip: !id });

  const [addData] = useAddProjectPaymentMutation();
  const [updateData] = useUpdateProjectPaymentMutation();
  const [removeData] = useDeleteProjectPaymentMutation();
  const { data: partyList } = useGetPartyQuery({ params });
  const { data: uomList } = useGetUomQuery({ params });



  function getNextDocId() {
    if (id || isLoading || isFetching) return;
    if (allData?.nextDocId) {
      setDocId(allData.nextDocId);
    }
  }

  // const getNextDocId = useCallback(() => {
  //     if (id || isLoading || isFetching) return
  //     if (allData?.nextDocId) {
  //         setDocId(allData.nextDocId)
  //     }
  // }, [allData, isLoading, isFetching, id])

  // useEffect(getNextDocId, [getNextDocId])

  const syncFormWithDb = useCallback(
    (data) => {
      if (id) {
        setReadOnly(true);
      } else {
        setReadOnly(false);
      }
      if (data?.docId) {
        setDocId(data?.docId);
      }
      setAliasName(data?.aliasName ? data?.aliasName : "");
      setDate(
        data?.createdAt
          ? moment.utc(data.createdAt).format("YYYY-MM-DD")
          : moment.utc(today).format("YYYY-MM-DD")
      );
      setInvoiceNo(data?.invoiceNo ? data?.invoiceNo : "");
      setInvoiceValue(data?.invoiceValue ? data?.invoiceValue : "");
      setAmount(data?.amount ? data?.amount : "");
      setPaymentMethod(data?.paymentMethod ? data?.paymentMethod : "CASH");
      setIsCompany(data?.isCompany ? data?.isCompany : false);
      setIsPersonal(data?.isPersonal ? data?.isPersonal : false);
      setGivenBy(data?.givenBy ? data?.givenBy : "");
      setCollectBy(data?.collectBy ? data?.collectBy : "");
      setContractAmount(data?.contractAmount ? data?.contractAmount : "");
      setUserDate(
        data?.userDate ? moment(data?.userDate).format("YYYY-MM-DD") : moment.utc(userDate).format("YYYY-MM-DD")
      );
      setNotes(data?.notes ? data?.notes : "");
      setTransportCost(data?.transportCost ? data?.transportCost : "");

      setExpenseItems(
        data?.projectExpensesItems ? data?.projectExpensesItems : []
      );
      setVendorId(data?.vendorId ? data?.vendorId : undefined);
      setExpenseType(data?.expenseType ? data?.expenseType : expenseType);
      setFoodCost(data?.foodCost ? data?.foodCost : "");
      setOtherExpenses(data?.otherExpenses ? data?.otherExpenses : "");

      childRecord.current = data?.childRecord ? data?.childRecord : 0;
    },
    [id]
  );

  useEffect(() => {
    syncFormWithDb(singleData?.data);
  }, [isSingleFetching, isSingleLoading, id, syncFormWithDb, singleData]);

  function getAliasName() {
    if (expenseType == "MATERIAL") {
      return findFromList(vendorId, partyList?.data, "name");
    }
    return aliasName;
  }

  const data = {
    aliasName: getAliasName(),
    companyId,
    id,
    projectId,
    otherExpenses,
    expenseType,
    foodCost,
    userDate,
    contractAmount,
    invoiceNo,
    notes,
    branchId,
    vendorId,
    invoiceValue,
    transportCost,
    isExpenses: true,
    projectExpensesItems: expenseItems,
    isCompany,
    isPersonal,
    collectBy,
    givenBy,
    paymentMethod,
  };

  const validateData = (data) => {
    if (expenseType == "LABOUR") {
      if (expenseItems?.length > 0) return true;
      return false;
    } else if (expenseType == "OTHER") {
      if (!data?.otherExpenses) return false;
      return true;
    } else if (expenseType == "CONTRACT") {
      if (!data?.contractAmount) return false;
      return true;
    } else {
      if (data.vendorId) {
        return true;
      }
      return false;
    }
  };

  const handleSubmitCustom = async (callback, data, text) => {
    try {
      let returnData = await callback(data).unwrap();
      if (returnData.statusCode === 0) {
        setId(returnData?.data?.id);

        toast.success(text + "Successfully");
      } else {
        toast.error(returnData?.message);
      }
      invalidateTagsDispatch()
    } catch (error) {
      console.log("handle");
    }
  };

  const saveData = () => {
    if (!validateData(data)) {
      toast.info("Please fill all required fields...!", {
        position: "top-center",
      });
      return;
    }

    if (!window.confirm("Are you sure save the details ...?")) {
      return;
    }

    if (id) {
      handleSubmitCustom(updateData, data, "Updated");
    } else {
      handleSubmitCustom(addData, data, "Added");
    }
  };

  const deleteData = async () => {
    if (id) {
      if (!window.confirm("Are you sure to delete...?")) {
        return;
      }
      try {
        let returnData = await removeData(id, { params }).unwrap();
        if (returnData.statusCode === 0) {
          setId("");
          syncFormWithDb(undefined);
          toast.success("Deleted Successfully");
        } else {
          toast.error(returnData?.message);
        }
        invalidateTagsDispatch()
      } catch (error) {
        toast.error("something went wrong");
      }
    }
  };

  const handleKeyDown = (event) => {
    let charCode = String.fromCharCode(event.which).toLowerCase();
    if ((event.ctrlKey || event.metaKey) && charCode === "s") {
      event.preventDefault();
      saveData();
    }
  };

  const onNew = () => {
    if (allData?.nextDocId) {
      setDocId(allData.nextDocId);
    }
    setId("");
    setReadOnly(false);
    setSearchValue("");
    syncFormWithDb(undefined);
    setInvoiceValue();
  };
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  function onDataClick(id) {
    setId("");
    setProjectId(id);
    setForm(true);
  }

  function removeItem(index) {
    setExpenseItems((contactDetails) => {
      return contactDetails.filter((_, i) => parseInt(i) !== parseInt(index));
    });
  }

  function handleInputChange(value, index, field) {
    const newBlend = structuredClone(expenseItems);

    newBlend[index][field] = value;

    setExpenseItems(newBlend);
  }

  useEffect(() => {
    if (id) return;
    if (allData?.nextDocId) {
      setDocId(allData.nextDocId || "");
    }
  }, [projectId, singleProjectData?.data?.clientId, setDocId, allData]);

  const findTotalAmount = (expenseId) => {
    let expense = allData?.data?.find(
      (val) => parseInt(val.id) === parseInt(expenseId)
    );
    let expenseType = expense?.expenseType;
    if (expenseType == "LABOUR") {
      let labourAmount = expense?.projectExpensesItems?.reduce(
        (a, b) => a + parseFloat(parseFloat(b.noOfShift || 0) * parseFloat(b.rate || 0)),
        0
      );
      let total =
        parseFloat(expense?.transportCost || 0) +
        parseFloat(expense?.foodCost || 0) +
        parseFloat(labourAmount || 0);
      return parseFloat(total).toFixed(2);
    } else if (expenseType == "OTHER") {
      return parseFloat(expense?.otherExpenses).toFixed(2) || 0;
    } else if (expenseType == "CONTRACT") {
      return parseFloat(expense?.contractAmount).toFixed(2) || 0;
    } else {
      let itemAmount = expense?.invoiceValue;
      let total =
        parseFloat(expense?.transportCost || 0) + parseFloat(itemAmount || 0);
      return parseFloat(total).toFixed(2);
    }
  };
  useEffect(() => {
    if (expenseItems.length >= 10) return;
    setExpenseItems((prev) => {
      const newArray = Array.from({ length: 5 - prev.length }, () => ({
        itemName: '', qty: '', uomId: '', rate: ''
      }));
      return [...prev, ...newArray];
    });
  }, [setExpenseItems, expenseItems.length]);



  const findTotalExpenses = () => {
    if (formExpenseType === "LABOUR") {
      let expense = allData?.data?.filter(
        (val) => val?.expenseType == "LABOUR"
      );
      let total = 0;
      if (expense?.length === 0) return "";
      for (let i = 0; i < expense.length; i++) {

        let labourAmount = expense[i]?.projectExpensesItems?.reduce((a, b) => a + parseFloat(parseFloat(b.noOfShift || 0) * parseFloat(b.rate || 0)), 0);
        console.log(labourAmount, "labourAmountlabourAmountlabourAmount")
        total += parseFloat(expense[i]?.transportCost || 0) + parseFloat(expense[i]?.foodCost || 0) + parseFloat(labourAmount || 0);
      }

      return parseFloat(total).toFixed(2);
    } else if (formExpenseType === "MATERIAL") {
      let total = 0;
      let expense = allData?.data?.filter(
        (val) => val?.expenseType == "MATERIAL"
      );
      if (expense?.length === 0) return "";
      for (let i = 0; i < expense.length; i++) {
        // let itemAmount = expense[i]?.projectExpensesItems?.reduce((a, b) => a + parseFloat(b.payment || 0), 0)
        let itemAmount = expense[i]?.invoiceValue;
        total +=
          parseFloat(expense[i]?.transportCost || 0) + parseFloat(itemAmount);
      }
      return parseFloat(total).toFixed(2);
    } else if (formExpenseType === "OTHER") {
      let total = 0;
      let expense = allData?.data?.filter(
        (val) => val?.expenseType === "OTHER"
      );
      if (expense?.length === 0) return "";

      total = expense?.reduce(
        (a, b) => a + parseFloat(b.otherExpenses || 0),
        0
      );
      return parseFloat(total).toFixed(2);
    } else if (formExpenseType === "CONTRACT") {
      let total = 0;
      let expense = allData?.data?.filter(
        (val) => val?.expenseType === "CONTRACT"
      );
      if (expense?.length === 0) return "";

      total = expense?.reduce(
        (a, b) => a + parseFloat(b.contractAmount || 0),
        0
      );
      return parseFloat(total).toFixed(2);
    } else {
      let total = 0;
      let expense = allData?.data;

      if (expense?.length === 0) return;

      for (let i = 0; i < expense?.length; i++) {
        let itemAmount = expense[i]?.invoiceValue;

        let labourAmount = expense[i]?.projectExpensesItems?.reduce(
          (a, b) => a + parseFloat(b.noOfShift * parseFloat(b.rate)),
          0
        );
        let otherAmount = parseFloat(expense[i]?.otherExpenses || 0);
        let contractAmount = parseFloat(expense[i]?.contractAmount || 0);

        total +=
          parseFloat(expense[i]?.transportCost || 0) +
          parseFloat(itemAmount || 0) +
          parseFloat(labourAmount || 0) +
          parseFloat(otherAmount) +
          parseFloat(contractAmount) +
          parseFloat(expense[i]?.foodCost || 0);
      }
      return parseFloat(total).toFixed(2);
    }
  };

  const tableHeaders = ["AliasName", "Pymt.Date ", "Total"];
  const tableDataNames = ["dataObj?.aliasName", "dataObj.invoiceValue"];

  if (!form)
    return <Report onNewButton={false} onClick={onDataClick} onNew={onNew} />;

  return (
    <div
      onKeyDown={handleKeyDown}
      className="md:items-start md:justify-items-center grid h-full bg-theme"
    >
      <Modal
        isOpen={formReport}
        onClose={() => setFormReport(false)}
        widthClass={"px-2 h-[100%] w-[90%]"}
      >
        <Report
          onClick={(id) => {
            setProjectId(id);
            setFormReport(false);
            setId("");
          }}
          onNewButton={false}
        />
      </Modal>
      <div className="flex flex-col frame w-full h-full">
        <FormHeader
          onNew={onNew}
          onClose={() => {
            setForm(false);
            setSearchValue("");
          }}
          model={MODEL}
          openReport={() => setFormReport(true)}
        // saveData={saveData} setReadOnly={setReadOnly}
        // deleteData={deleteData}
        />

        <div className="flex-1 grid grid-cols-1 md:grid-cols-4 gap-x-2 overflow-clip">
          <div className="col-span-3 grid md:grid-cols-1 border overflow-auto">
            <div className="mr-1 md:ml-2">
              <fieldset className="frame my-1">
                <legend className="sub-heading">Expenses</legend>

                <div className="grid grid-cols-7 mt-5 gap-4">
                  <div className="col-span-1">
                    <DisabledInput
                      name="ProjectDoc.id"
                      value={singleProjectData?.data?.docId || ""}
                      disabled
                      required={true}
                      readOnly={true}
                      className="w-full"
                    />
                  </div>
                  <div className="col-span-1">
                    <DisabledInput
                      name="Quote Id"
                      value={singleProjectData?.data?.Quotes.map(item=>item.docId) || ""}
                      disabled
                      required={true}
                      readOnly={true}
                      className="w-full"
                    />
                  </div>
                  <div className="col-span-1">
                    <DisabledInput
                      name="Lead Id"
                      value={singleProjectData?.data?.Quotes.map(item=>item.LeadForm.docId) || ""}
                      disabled
                      required={true}
                      readOnly={true}
                      className="w-full"
                    />
                  </div>

                  <div className="col-span-1">
                    <DisabledInput
                      name="ClientName"
                      value={clientData?.data?.name || ""}
                      disabled
                      required={true}
                      readOnly={true}
                      className="w-full"
                    />
                  </div>

                  <div className="col-span-1">
                    <DisabledInput
                      name="ProjectName"
                      value={singleProjectData?.data?.projectName || ""}
                      disabled
                      required={true}
                      readOnly={true}
                      className="w-full"
                    />
                  </div>

                  <div className="col-span-1">
                    <DisabledInput
                      name="Doc Id."
                      value={docId}
                      required={true}
                      readOnly={true}
                      className="w-full"
                    />
                  </div>

                  <div className="flex flex-col items-start gap-1 text-sm">
                    <label className="text-sm">
                      Expense <span className="text-red-600">*</span>
                    </label>
                    <select
                      disabled={readOnly}
                      className="text-left text-sm w-full rounded py-1 table-data-input border border-gray-400"
                      value={expenseType}
                      onChange={(e) => setExpenseType(e.target.value)}
                    >
                      <option className="text-gray-600" hidden></option>
                      {expenses.map((data) => (
                        <option value={data.value} key={data.value}>
                          {data.show}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="flex">
                  <button
                    className="mt-2 bg-blue-500 text-white px-4 mr-10 py-2 rounded ml-auto"
                    onClick={openModal}
                  >
                    Open Expense Form
                  </button>
                </div>

                <Modal1 isOpen={isModalOpen} onClose={closeModal}>
                  <>
                    {expenseType === "LABOUR" ? (
                      <LabourExpense
                        userDate={userDate}
                        setUserDate={setUserDate}
                        aliasName={aliasName}
                        setAliasName={setAliasName}
                        setFoodCost={setFoodCost}
                        foodCost={foodCost}
                        transportCost={transportCost}
                        setTransportCost={setTransportCost}
                        readOnly={readOnly}
                        expenseItems={expenseItems}
                        setExpenseItems={setExpenseItems}
                        childRecord={childRecord}

                      />
                    ) : expenseType === "MATERIAL" ? (
                      <>
                        <div className="w-full">
                          <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
                            <div className="col-span-1">
                              <VendorCreate
                                setPartyId={setVendorId}
                                partyId={vendorId}
                                name="Vendor Name"
                                isVendor={true}
                              />
                            </div>

                            <div className="col-span-1">
                              <TextInput
                                name="InvoiceNo"
                                value={invoiceNo}
                                setValue={setInvoiceNo}
                                className="w-full"
                                readOnly={readOnly}
                              />
                            </div>

                            <div className="col-span-1">
                              <TextInput
                                name="InvoiceAmount"
                                value={invoiceValue}
                                setValue={setInvoiceValue}
                                className="w-full"
                                readOnly={readOnly}
                              />
                            </div>

                            <div className="col-span-1 flex flex-col">
                              <label className="text-sm font-medium text-gray-700 pb-1">
                                Methods <span className="text-red-600">*</span>
                              </label>
                              <select
                                disabled={readOnly}
                                className=" rounded py-1.5 px-3 border text-xs border-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-600"
                                value={paymentMethod}
                                onChange={(e) => setPaymentMethod(e.target.value)}
                              >
                                <option className="text-gray-600 text-sm" hidden></option>
                                {paymentMethods?.map((data) => (
                                  <option value={data.value} key={data.show}>
                                    {data.show}
                                  </option>
                                ))}
                              </select>
                            </div>

                          </div>
                        </div>

                        <div className="w-full mt-4">
                          <div className="grid grid-cols-1 md:grid-cols-4 gap-4">


                            <div className="col-span-1 flex flex-col gap-2  ">
                              <label className="text-sm">Transport Cost</label>
                              <input
                                type="number"
                                className="focus:outline-none border text-sm border-gray-500 p-1 rounded"
                                value={transportCost}
                                onChange={(e) => setTransportCost(e.target.value)}
                                readOnly={readOnly}
                              />
                            </div>

                            {paymentMethod !== "CASH" && paymentMethod && (
                              <div className="col-span-2 flex items-center gap-4">
                                <CheckBox
                                  name="Company.Ac"
                                  value={isCompany}
                                  setValue={setIsCompany}
                                  readOnly={readOnly}
                                />
                                <CheckBox
                                  name="Personal.Ac"
                                  value={isPersonal}
                                  setValue={setIsPersonal}
                                  readOnly={readOnly}
                                />
                              </div>
                            )}

                            {paymentMethod === "CASH" && (
                              <div className="col-span-2 grid grid-cols-2 gap-4">
                                <TextInput
                                  type="text"
                                  required={true}
                                  name="Given.By"
                                  value={givenBy}
                                  setValue={setGivenBy}
                                  className="w-full text-sm"
                                  inputClass="p-1"
                                  readOnly={readOnly}
                                />
                                <TextInput
                                  type="text"
                                  required={true}
                                  name="Collected.By"
                                  value={collectBy}
                                  setValue={setCollectBy}
                                  className="w-full text-sm"
                                  inputClass="p-1"
                                  readOnly={readOnly}
                                />

                              </div>
                            )}
                            <div className="flex flex-col items-start gap-1 text-sm">
                              <label>Expense Date</label>
                              <input
                                type="date"
                                className="focus:outline-none border border-gray-500 rounded w-28 p-0.5"
                                value={userDate}
                                onChange={(e) => setUserDate(e.target.value)}
                                readOnly={readOnly}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="col-span-1 flex flex-col gap-2">
                          <label className="text-sm">Note:</label>
                          <textarea
                            className="focus:outline-none border border-gray-500 rounded text-sm w-full h-9"
                            value={notes}
                            onChange={(e) => setNotes(e.target.value)}
                            readOnly={readOnly}
                          ></textarea>
                        </div>

                        <div className="mt-4 w-full p-5 border border-gray-500 rounded-lg">
                          <table className="w-full border border-gray-500">
                            <thead>
                              <tr className="bg-gray-100">
                                <th className="border border-gray-500 text-sm p-1">
                                  S.no
                                </th>
                                <th className="border border-gray-500 text-sm p-1">
                                  Item
                                </th>
                                <th className="border border-gray-500 text-sm p-1">
                                  Qty
                                </th>
                                <th className="border border-gray-500 text-sm p-1">
                                  Unit
                                </th>
                                <th className="border border-gray-500 text-sm p-1">
                                  Rate
                                </th>
                                <th className="border border-gray-500 text-sm p-1">
                                  <button
                                    type="button"
                                    className="text-green-700 p-1"
                                    onClick={() => {
                                      let newExpenseItems = [...expenseItems];
                                      newExpenseItems.push({});
                                      setExpenseItems(newExpenseItems);
                                    }}
                                  >
                                    <FontAwesomeIcon icon={faUserPlus} />
                                  </button>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {expenseItems?.map((item, index) => (
                                <tr key={index} className="text-center">
                                  <td className="border border-gray-500 text-xs p-1">
                                    {index + 1}
                                  </td>
                                  <td className="border border-gray-500 text-xs p-1">
                                    <input
                                      className="w-full p-1 capitalize"
                                      type="text"
                                      value={item.itemName}
                                      onChange={(e) =>
                                        handleInputChange(
                                          e.target.value,
                                          index,
                                          "itemName"
                                        )
                                      }
                                      required={true}
                                      readOnly={readOnly}
                                      disabled={childRecord.current > 0}
                                    />
                                  </td>
                                  <td className="border border-gray-500 text-xs p-1">
                                    <input
                                      className="w-full p-1 capitalize"
                                      type="text"
                                      value={item.qty}
                                      onChange={(e) =>
                                        handleInputChange(
                                          e.target.value,
                                          index,
                                          "qty"
                                        )
                                      }
                                      required={true}
                                      readOnly={readOnly}
                                      disabled={childRecord.current > 0}
                                    />
                                  </td>
                                  <td className="border border-gray-500 text-xs p-1">
                                    <select
                                      readOnly={readOnly}
                                      className="w-full rounded py-1 border border-gray-400"
                                      value={item.uomId}
                                      onChange={(e) =>
                                        handleInputChange(
                                          e.target.value,
                                          index,
                                          "uomId"
                                        )
                                      }
                                    >
                                      <option className="text-gray-600"></option>
                                      {uomList?.data?.map((uom) => (
                                        <option value={uom.id} key={uom.id}>
                                          {uom.name}
                                        </option>
                                      ))}
                                    </select>
                                  </td>
                                  <td className="border border-gray-500 text-xs p-1">
                                    <input
                                      className="w-full p-1 capitalize"
                                      type="text"
                                      value={item.rate}
                                      onChange={(e) =>
                                        handleInputChange(
                                          e.target.value,
                                          index,
                                          "rate"
                                        )
                                      }
                                      required={true}
                                      readOnly={readOnly}
                                      disabled={childRecord.current > 0}
                                    />
                                  </td>
                                  <td className="border border-gray-500 text-xs p-1">
                                    <button
                                      type="button"
                                      onClick={() => removeItem(index)}
                                      className="text-red-600"
                                    >
                                      <FontAwesomeIcon icon={faTrashCan} />
                                    </button>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </>
                    ) : expenseType === "CONTRACT" ? (
                      <>
                        <div className="grid grid-cols-3 gap-x-3">
                          <TextInput
                            name={"ContractorName"}
                            value={aliasName}
                            setValue={setAliasName}
                            className={"w-full"}
                            readOnly={readOnly}
                          />
                          <TextInput
                            name={"Amount"}
                            value={contractAmount}
                            setValue={setContractAmount}
                            className={"w-full"}
                            readOnly={readOnly}
                          />
                          <div className="flex flex-col items-start gap-1 text-sm">
                            <label>Expense Date</label>
                            <input
                              type="date"
                              className="focus:outline-none border border-gray-500 rounded w-28 p-0.5"
                              value={userDate}
                              onChange={(e) => setUserDate(e.target.value)}
                              readOnly={readOnly}
                            />
                          </div>

                        </div>
                        <div className="flex justify-start text-xs flex-col w-full mt-2">
                          <label className="px-1">Notes :</label>
                          <textarea
                            className={`focus:outline-none md:col-span-2 border-gray-500 border rounded md:w-3/4 h-9 md:ml-1 py-1`}
                            value={notes}
                            onChange={(e) => setNotes(e.target.value)}
                            readOnly={readOnly}
                          >
                            {" "}
                          </textarea>
                        </div>

                      </>
                    ) : (
                      <>
                        <div className="grid grid-cols-3 gap-x-3">
                          <TextInput
                            name={"OtherExpense"}
                            value={aliasName}
                            setValue={setAliasName}
                            className={"w-full"}
                            readOnly={readOnly}
                          />
                          <TextInput
                            name={"Amount"}
                            value={otherExpenses}
                            setValue={setOtherExpenses}
                            className={"w-full"}
                            readOnly={readOnly}
                          />
                          <div className="flex flex-col items-start gap-1 text-sm">
                            <label>Expense Date</label>
                            <input
                              type="date"
                              className="focus:outline-none border border-gray-500 rounded w-28 p-0.5"
                              value={userDate}
                              onChange={(e) => setUserDate(e.target.value)}
                              readOnly={readOnly}
                            />
                          </div>

                        </div>
                        <div className="flex justify-start text-xs flex-col w-full mt-2">
                          <label className="px-1">Notes :</label>
                          <textarea
                            className={`focus:outline-none md:col-span-2 border-gray-500 border rounded md:w-3/4 h-9 md:ml-1 py-1`}
                            value={notes}
                            onChange={(e) => setNotes(e.target.value)}
                            readOnly={readOnly}
                          >
                            {" "}
                          </textarea>
                        </div>
                      </>
                    )}

                    <div className="flex gap-x-10 pb-5 pt-9 justify-center ">
                      <button
                        className="flex items-center justify-center text-center bg-blue-600 text-white rounded-md p-2 w-32 h-10 mt-2 text-md hover:bg-blue-700 transition duration-200"
                        onClick={onNew}
                      >
                        <FaPlus className="mr-2" />
                        New
                      </button>

                      <button
                        className="flex items-center  justify-center bg-yellow-600 text-white rounded-md p-2 w-32 h-10 mt-2 text-md hover:bg-yellow-700 transition duration-200"
                        onClick={() => setReadOnly(false)}
                      >
                        <FaEdit className="mr-2" />
                        Edit
                      </button>
                      <button
                        className="flex items-center bg-green-600 justify-center text-white rounded-md p-2 w-32 h-10 mt-2 text-md hover:bg-green-700 transition duration-200"
                        onClick={saveData}
                      >
                        <FaSave className="mr-2" />
                        Save
                      </button>
                      <button
                        className="flex items-center bg-red-600 text-white justify-center rounded-md p-2 w-32 h-10 mt-2 text-md hover:bg-red-700 transition duration-200"
                        onClick={() => setIsModalOpen(false)}
                      >
                        <FaTimes className="mr-2" />
                        Close
                      </button>

                    </div>
                  </>
                </Modal1>
              </fieldset>
            </div>
          </div>

          <div className="frame hidden md:block overflow-x-hidden">
            <FormReport
              formExpenseType={formExpenseType}
              setFormExpenseType={setFormExpenseType}
              projectExpenses={true}
              findTotalExpenses={findTotalExpenses}
              findTotalAmount={findTotalAmount}
              searchValue={searchValue}
              setSearchValue={setSearchValue}
              setId={setId}
              tableHeaders={tableHeaders}
              tableDataNames={tableDataNames}
              data={allData?.data}
              loading={isLoading || isFetching}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
